import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class CareersService {
  // POST request to fetch Career page section wise
  async getCareersSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.CAREER_PAGE);
    return response;
  }
}

const careersService = new CareersService();
export default careersService;
