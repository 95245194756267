import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class AboutUsService {
  // POST request to fetch About Us page section wise
  async getAboutUsSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.ABOUT_US_PAGE, {
      Page: 'AboutUs',
      Section: payload.sectionName,
    });


    return response;
  }
}

const aboutUsService = new AboutUsService();
export default aboutUsService;
