import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class FAQService {
  // POST request to fetch FAQ page section wise
  async getFAQSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.FAQ_PAGE);
    return response;

  }
}

const faqService = new FAQService();
export default faqService;
