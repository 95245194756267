import { createSlice } from '@reduxjs/toolkit';

// Initial state for TermsOfUse slice
const initialState = {
  section1: {},
};

const termsOfUseSlice = createSlice({
  name: 'termsOfUse',
  initialState,
  reducers: {
    setTermsOfUseSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const termsOfUseActions = termsOfUseSlice.actions;
export const termsOfUseReducer = termsOfUseSlice.reducer;

export const TermsOfUseActionTypes = {
  FETCH_TERMS_OF_USE: 'TermsOfUse/Fetch',
};
