import React from 'react';
import PrivacyHeader from '../../../assets/PrivacyPolicyHeader.jpg';
import PrivacyHeaderMobile from '../../../assets/Privacy-policyHeaderMobile.jpg';
import parse from 'html-react-parser';

const Header = ({ section1 }) => {
  let title = '';
  let image = '';
  let imageAlt = '';
  let image2 = '';
  let image2Alt = '';

  if (section1?.Heading) {
    title = parse(section1?.Heading);
    image = section1?.BannerImage?.url;
    imageAlt = section1?.BannerImage?.altTag;
    image2 = section1?.BannerImageMobile?.url;
    image2Alt = section1?.BannerImageMobile?.altTag;
  }
  return (
    <div className='container-fluid p-0 StickScroll'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAlt} className='WebImg' />
        {/* <img src={PrivacyHeader} alt='' className='WebImg' /> */}
        <img src={image2} alt={image2Alt} className='MobileImg' />
        {/* <img src={PrivacyHeaderMobile} alt='' className='MobileImg' /> */}
        <div className='inSideHeaderCaption' data-aos='fade-right'>
          <h1> {title}</h1>
          {/* <h1> PRIVACY POLICY</h1> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
