import React, { useEffect } from 'react';
import Header from '../../components/PrivacyPolicy/Header/Header';
import Body from '../../components/PrivacyPolicy/Body/Body';
import { useDispatch, useSelector } from 'react-redux';
import { getPrivacySection } from '../../actions/privacy';
import { Helmet } from 'react-helmet-async';
import parse from 'html-react-parser';

const PrivacyPolicy = () => {
  const dispatch = useDispatch();
  const { SectionData } = useSelector((state) => state.privacy);



  useEffect(() => {
    dispatch(getPrivacySection({ sectionName: '' }));
  }, []);

  return (
    <>
      <Helmet>{SectionData && parse(SectionData?.SeoTags)}</Helmet>
      <Header section1={SectionData} />
      <Body section1={SectionData} />
    </>
  );
};

export default PrivacyPolicy;
