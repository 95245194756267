import outmazedLogo from '../../assets/footerLogo.svg';
import { PAGE_URL } from '../../config/appConfig';
export const getHeaderData = () => {
  return [
    {
      label: 'ABOUT US',
      href: PAGE_URL.ABOUT,
    },
    {
      label: 'SERVICES',
      href: PAGE_URL.SERVICE,
    },
    {
      label: 'PROJECTS',
      href: PAGE_URL.PROJECTS,
    },
    {
      label: '',
      image: outmazedLogo,
      href: PAGE_URL.HOMEPAGE,
    },
    {
      label: 'THEMES',
      href: PAGE_URL.THEME,
    },
    {
      label: 'CAREERS',
      href: PAGE_URL.CAREERS,
    },
    {
      label: 'CONTACT US',
      href: PAGE_URL.CONTACT_US,
    },
  ];
};
