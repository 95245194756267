import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';
// import { response } from './data/ServiceData';

class ServicesService {
  // POST request to fetch Services page section wise
  async getServicesSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.SERVICE_PAGE);


    return response;
  }
}

const servicesService = new ServicesService();
export default servicesService;
