import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import projectsService from '../services/ProjectsService';
import {
  ProjectsActionTypes,
  projectsActions,
} from '../store/slices/projectsSslice';

// saga handler to fetch projects sectionwise and update redux store as per the api payload
export const fetchProjectsSection = function* (action) {
  try {
    const response = yield call(
      projectsService.getProjectsSection,
      action.payload
    );
    const projectResponse = yield get(response, 'data');


    yield put(
      projectsActions.setProjectsSection({
        sectionName: action.payload.sectionName,
        data: projectResponse
      })
    );

  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* projectsSaga() {
  yield takeEvery(ProjectsActionTypes.FETCH_PROJECTS, fetchProjectsSection);
}
