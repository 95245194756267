import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class HomeService {
  // Get Request to get data from OMD Backend api
  async getHomeSection(payload) {

    const response = await HttpClient.get(OMD_API_ENDPOINTS.HOME_PAGE);

    return response;
  }
}

const homeService = new HomeService();
export default homeService;
