import interiorImg from '../../../assets/Service-Interior-Design.jpg';
import renovationImg from '../../../assets/Service-RENOVATION.jpg';
import projectImg from '../../../assets/Service-PROJECT-MANAGEMENT.jpg';
import furnishImg from '../../../assets/Service-FURNISHINGS.jpg';
import fitOutImg from '../../../assets/Service-FITOUTS.jpg';
import poolsImg from '../../../assets/Service-POOLS.jpg';
import maintinanceImg from '../../../assets/Service-Maintinanc.jpg';
import interiorIcon from '../../../assets/Home-Icon-Interior-Design.svg';
import renovationIcon from '../../../assets/Home-Icon-Renovation.svg';
import projectIcon from '../../../assets/Home-Icon-ProjectManagement.svg';
import furnishingIcon from '../../../assets/Home-Icon-Furnishings.svg';
import fitoutsIcon from '../../../assets/Home-Icon-FIT-OUTS.svg';
import outdoorIcon from '../../../assets/Home-Icon-Outdoors.svg';
import maintinanceIcon from '../../../assets/Home-Icon-Maintinance.svg';
import interiorBg from '../../../assets/ServiceInteriorBg.png';
import furnishBg from '../../../assets/ServiceFurnishBg.png';
import fitsBg from '../../../assets/ServiceFitsBg.png';
import projectBg from '../../../assets/ServiceProjectBg.png';
import outdoorBg from '../../../assets/ServiceOutdoorBg.png';
import renovationBg from '../../../assets/ServiceRenovationBg.png';
import maintinanceBg from '../../../assets/ServiceMaintinanceBg.png';
import { useSelector } from 'react-redux';
import { PAGE_URL } from '../../../config/appConfig';

const getFormattedData = (heading) => {
  return heading.toLowerCase().replace(/\s+/g, '_');
};

const useServiceFeatures = () => {
  return useSelector((state) => {
    const { SectionData } = state.services;
    let services = [];

    if (SectionData && SectionData.Services) {
      services = SectionData.Services.map(
        (service, index) => ({
          id: index,
          serviceNumber: index,
          heading: service.Title.toUpperCase(),
          description: service.Description,
          image: service.Image.url,
          imageAlt: service.Image.altTag,
          bgimage: service.BgImage.url,
          bgImageAlt: service.BgImage.altTag,
          icon: service.Icon.url,
          iconAlt: service.Icon.altTag,
          hiddenDescription: service.HiddenTitle,
          detail: service.ShortDescription,
          locationId: `#${getFormattedData(service.Title)}`,
          href: `${PAGE_URL.SERVICE}#${getFormattedData(service.Title)}`,
        })
      );
    }

    return services;
  });
};

export default useServiceFeatures;

// services = [
//   {
//     id: '01',
//     serviceNumber: '01',
//     heading: 'INTERIOR DESIGN',
//     description:
//       'With our Interior Design expertise, we can help you unlock the full potential of your interiors. We thoughtfully design spaces that capture your individuality while expertly fusing functionality and style. From conceptualization to finishing touches, we create personalized designs that accentuate your individuality and lifestyle.',
//     image: interiorImg,
//     bgimage: interiorBg,
//     icon: interiorIcon,
//     hiddenDescription: 'Feel the unique experience.',
//   },
//   {
//     id: '02',
//     serviceNumber: '02',
//     heading: 'RENOVATION',
//     description:
//       "We renovate every nook and cranny, whether it's a single room or the entire house. Our staff skillfully blends contemporary elements with your unique style, ensuring each makeover becomes a masterpiece tailored to your vision and comfo",
//     image: renovationImg,
//     bgimage: renovationBg,
//     icon: renovationIcon,
//     hiddenDescription: 'Revamp your home.',
//   },
//   {
//     id: '03',
//     serviceNumber: '03',
//     heading: 'FITOUTS & JOINERY',
//     description:
//       'Experience personalized spaces with our Joinery & Fitouts service. To make sure that your unique style shines through, we thoughtfully design spaces that combine beauty and functionality. Our goal is to design spaces that highlight sophistication and creativity while reflecting your tastes and needs.',
//     image: fitOutImg,
//     bgimage: fitsBg,
//     icon: fitoutsIcon,
//     hiddenDescription: 'With our best craftsmanship',
//   },
//   {
//     id: '04',
//     serviceNumber: '04',
//     heading: 'OUTDOOR',
//     description:
//       'Discover a world where nature meets great design. We extend the essence of your house outside by designing gorgeous landscapes that combine the best elements of nature with innovative designs and pools that complement your way of living. Our expertise in landscaping ensures lush gardens and peaceful pathways, and our exquisitely designed swimming pools transform your backyard into an exotic paradise that provides an entertainment zone and a calming retreat.',
//     image: poolsImg,
//     bgimage: outdoorBg,
//     icon: outdoorIcon,
//     hiddenDescription: 'Pools and Landscaping',
//   },
//   {
//     id: '05',
//     serviceNumber: '05',
//     heading: 'FURNISHINGS',
//     description:
//       'Transform your interiors into personalized havens with our carefully chosen furnishings for your interior spaces. From statement pieces to subtle accents, discover timeless elegance that perfectly blends functionality with style.',
//     image: furnishImg,
//     bgimage: furnishBg,
//     icon: furnishingIcon,
//     hiddenDescription: 'The homely feeling.',
//   },
//   {
//     id: '06',
//     serviceNumber: '06',
//     heading: 'PROJECT MANAGEMENT',
//     description:
//       'Discover flawless execution with our Project Management service. We handle every detail carefully, prioritizing quality and precision at every step of the way. Our goal is to transform your visions into reality, managing the project with unwavering dedication to bring your ideas to life with excellence.',
//     image: projectImg,
//     bgimage: projectBg,
//     icon: projectIcon,
//     hiddenDescription: 'End to end management.',
//   },
//   {
//     id: '07',
//     serviceNumber: '07',
//     heading: 'Maintenance',
//     description:
//       "We ensure the integrity, safety, and functionality of your property. With a comprehensive range of services including Civil Services (Structural Maintenance), MEP (Electrical and Plumbing), Outdoor Maintenance (Swimming Pool and Garden), Carpentry Services (Furniture repair and restoration, New fittings), Handyman Services (General repairs and maintenance tasks), and AC or Duct Cleaning and Maintenance, we're here to cater to all your maintenance needs. Our experienced technicians are equipped with the latest tools and techniques, delivering efficient and effective solutions to keep your property in top condition.",
//     image: maintinanceImg,
//     bgimage: maintinanceBg,
//     icon: maintinanceIcon,
//     hiddenDescription: 'With our top-tier engineers',
//   },
// ];
