import { createSlice } from '@reduxjs/toolkit';

// Initial state for site info slice
const initialState = {
  section1: {},
};

const siteInfoSlice = createSlice({
  name: 'siteInfo',
  initialState,
  reducers: {
    setSiteInfoSection: (state, action) => {
      const { sectionName, data } = action.payload;
      state[sectionName] = data;
    },
  },
});

export const siteInfoActions = siteInfoSlice.actions;
export const siteInfoReducer = siteInfoSlice.reducer;

export const SiteInfoActionTypes = {
  FETCH_SITE_INFO: 'SiteInfo/Fetch',
};
