import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class TermsOfUseService {
  // POST request to fetch TermsOfUse policy page section wise
  async getTermsOfUseSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.TERMS_PAGE);

    return response;
  }
}

const termsOfUseService = new TermsOfUseService();
export default termsOfUseService;
