import { createSlice } from '@reduxjs/toolkit';

// Initial state for FAQ slice
const initialState = {
  section1: {},
};

const faqSlice = createSlice({
  name: 'faq',
  initialState,
  reducers: {
    setFAQSection: (state, action) => {
      const { payload } = action;
      state[payload.sectionName] = payload.data;
    },
  },
});

export const faqActions = faqSlice.actions;
export const faqReducer = faqSlice.reducer;

export const FAQActionTypes = {
  FETCH_FAQ: 'FAQ/Fetch',
};
