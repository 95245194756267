import { call, put, takeEvery } from 'redux-saga/effects';
import { get } from 'lodash';
import siteInfoService from '../services/SiteInfoService';
import {
  SiteInfoActionTypes,
  siteInfoActions,
} from '../store/slices/siteInfoSlice';

// saga handler to fetch SiteInfo sectionwise and update redux store as per the api payload
export const fetchSiteInfoSection = function* (action) {
  try {
    const response = yield call(
      siteInfoService.getSiteInfoSection,
      action.payload
    );
    const responseData = yield get(response, 'data');


    yield put(
      siteInfoActions.setSiteInfoSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* siteInfoSaga() {
  yield takeEvery(SiteInfoActionTypes.FETCH_SITE_INFO, fetchSiteInfoSection);
}
