import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class SiteInfoService {
  // POST request to fetch site info section wise
  async getSiteInfoSection(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.SITE_INFO);
    return response;
  }
}

const siteInfoService = new SiteInfoService();
export default siteInfoService;
