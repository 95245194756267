import styled, { createGlobalStyle } from 'styled-components';
import { DEVICES } from '../config/appConfig';
import footerBg from '../assets/Footer_bg.png';
import ArrowRight from '../assets/SlideBigArrowRight.png';
import ArrowLeft from '../assets/SlideBigArrowLeft.png';
import MenuIcon from '../assets/NavIcon.png';
import MenuIconClose from '../assets/NavIconClose.png';

export default createGlobalStyle`
.StopScroll {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
:root {
  --white: #ffffff;
  --danger: #e65252;
  --orange: #f47a57;
  --lightGray: #cacace;
  --darkLight: #3e3e3e;
  --dark: #1d1d1d;
  --dark67: #676767;
  --dark25: #252525;
  --LemonMilk--font-family: "Poppins", sans-serif;
  --font-Poppins: "Poppins", sans-serif;
  /* */
  --lgt-font-Weight: 300;
  --Rgl-font-weight: 400;
  --Sbld-font-weight: 500;
  --Bld-font-weight: 600;
  --font-size14: 14px;
  --font-size16: 16px;
  --font-size18: 18px;
  --font-size20: 20px;
  --font-size24: 24px;
  --font-size26: 26px;
  --font-size28: 28px;
  --font-size32: 32px;
  --font-size34: 34px;
  --font-size36: 36px;
  --font-size38: 38px;
  --font-size40: 40px;
  --font-size48: 48px;
  --font-size54: 54px;
  --font-size70: 70px;
  --font-size80: 80px;
  --font-size90: 90px;
  --dark-gradient: linear-gradient(to bottom, var(--darkLight), var(--dark));
  --dark-rev-gradient: linear-gradient(to top, var(--darkLight), var(--dark));
  --paddingbottom: 15px;
  --border-radius4: 4px;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}
html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  -webkit-text-rendering: optimizeSpeed;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: none;
  scroll-behavior: initial !important;
  width: 100%;
  min-height: 100%;
  font-family: 'Poppins', sans-serif;
  scrollbar-gutter: stable;
}
body {
  background: var(--dark);
  font-weight: var(--font-weight400);
  font-size: var(--font-size14);
  font-family: 'Poppins', sans-serif;
}
sup {
  top: -0.17em;font-weight:var(--lgt-font-Weight);font-size:inherit !important;
} 
.Finfo ul li sup{
  top:0.17em;
    font-weight: var(--lgt-font-Weight);
    font-size: var(--font-size24) !important; 
}
p sup{
  top:0.17em;
    font-weight: var(--lgt-font-Weight);
    font-size: var(--font-size24) !important;
    line-height: 0px; 
}
a{text-decoration:none;}
textarea:focus,
input[type='text']:focus,
input[type='password']:focus,
input[type='datetime']:focus,
input[type='datetime-local']:focus,
input[type='date']:focus,
input[type='month']:focus,
input[type='time']:focus,
input[type='week']:focus,
input[type='number']:focus,
input[type='email']:focus,
input[type='url']:focus,
input[type='search']:focus,
input[type='tel']:focus,
input[type='color']:focus,
.uneditable-input:focus {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0) inset, 0 0 8px rgba(126, 239, 104, 0);
  outline: 0 none;
}
.Navactive{color: var(--orange) !important;font-weight:var(--Bld-font-weight);}
#navbarNav .navbar-nav .nav-link{color:var(--lightGray);}
#navbarNav .navbar-nav .NavactiveSer, .NavactiveSer:focus{border-bottom:solid 2px var(--orange);font-weight:var(--Bld-font-weight);color:var(--white) ;}
.zIndex2 {
  z-index: 2;
  position: relative;
}
.DarkLightBg{background:var(--darkLight) !important}
.nav-item {
  align-items: center;
  display: flex;
  //margin: 0px 45px;
  font-size: var(--font-size16);
  font-family: var(--LemonMilk--font-family);
}
.DeleteColor{color:var(--danger)}
.get-in-touch-mobile{
  display: none;
}
.navbar-expand-lg .navbar-nav, .navbar-expand-md .navbar-nav {
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
.customCaption {
  position: absolute;
  z-index: 9;
  left: 5%;
  bottom: 10%;
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size40);
  font-weight: var(--Bld-font-weight);
  
  padding: 0px;
  text-align: left;
  border-left: solid 3px var(--orange);
  padding-left: 10px;
}
.customCaption span {
  display: block;padding:0px 0px;letter-spacing: 0px;line-height: normal;
}
.carousel-control-next,
.carousel-control-prev {
  width: 5%;
}
.CustomFC::placeholder {
  color: var(--dark67);
  opacity: 0.6; /* Firefox */
}
.linkWhite {
  font-size: 16px;
  color: var(--white);
  text-decoration: underline;
  font-weight: 500;
}
.linkWhite i {
  margin-left: 10px;
}
.btn {
  padding: 8px 26px;
  margin: 10px auto;
  font-size: var(--font-size16);
  font-weight: 400;
  border-radius: 4px;
  outline: none;
  text-decoration: none;
  color: #784ba0;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0 6px 30px rgba(0, 0, 0, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.3);
  cursor: pointer;
}
.btn:hover,
.btn:focus,
.btn:active,
.btn:visited {
  transition-timing-function: cubic-bezier(0.6, 4, 0.3, 0.8);
  animation: gelatine 0.5s 1;
}

@keyframes gelatine {
  0%,
  100% {
    transform: scale(1, 1);
  }
  25% {
    transform: scale(0.9, 1.1);
  }
  50% {
    transform: scale(1.1, 0.9);
  }
  75% {
    transform: scale(0.95, 1.05);
  }
}
p {
  color: var(--lightGray);
  font-size: var(--font-size14);
  font-weight: var(--font-weight400);
  margin: 0px;
}
.bdrRdarkLight{border-right: solid 1px var(--darkLight);}
.bdrLdarkLight{border-left: solid 1px var(--darkLight);}
.FW300 {
  font-weight: var(--Rgl-font-weight);
}
.FW400 {
  font-weight: 400;
}
.FW500 {
  font-weight: 500;
}
.FW600 {
  font-weight: 600;
}
.pt-10 {
  padding-top: 10px !important;
}
.pt-15 {
  padding-top: 15px !important;
}
.pt-20 {
  padding-top: 20px !important;
}
.pt-25 {
  padding-top: 25px !important;
}
.pt-45 {
  padding-top: 45px !important;
}
.pt-90 {
  padding-top: 90px !important;
}
.pb-10 {
  padding-bottom: 10px !important;
}
.pb-15 {
  padding-bottom: 15px !important;
}
.pb-20 {
  padding-bottom: 20px !important;
}
.pb-25 {
  padding-bottom: 25px !important;
}
.pb-45 {
  padding-bottom: 45px !important;
}
.pb-90 {
  padding-bottom: 90px !important;
}
.pr-10 {
  padding-right: 10px !important;
}
.pr-15 {
  padding-right: 15px !important;
}
.pr-20 {
  padding-right: 20px !important;
}
.pr-25 {
  padding-right: 25px !important;
}
.pr-45 {
  padding-right: 45px !important;
}
.pr-90 {
  padding-right: 90px !important;
}
.pl-10 {
  padding-left: 10px !important;
}
.pl-15 {
  padding-left: 15px !important;
}
.pl-20 {
  padding-left: 20px !important;
}
.pl-25 {
  padding-left: 25px !important;
}
.pl-45 {
  padding-left: 45px !important;
}
.pl-90 {
  padding-left: 90px !important;
}
.ptb-10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.ptb-15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}
.ptb-20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
.ptb-25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}
.ptb-45 {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
}
.ptb-90 {
  padding-top: 90px !important;
  padding-bottom: 90px !important;
}
.mt-46 {
  margin-top: -46px;
}
.TItle40 {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size40);text-transform:uppercase;
  font-weight:var(--Bld-font-weight);line-height:normal;
}
.SubTitleLM {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size26);text-transform:uppercase;
  font-weight: var(--Rgl-font-weight);
  padding-bottom: var(--paddingbottom);
  
}
.SubTitleLM h3 {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size26);text-transform:uppercase;
  font-weight: var(--Rgl-font-weight);
  padding-bottom: var(--paddingbottom);
  color: var(--orange);
  margin-bottom: 0px;
  
}
.SubSmallTItle {
  color: var(--white);
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size16);text-transform:uppercase;
  font-weight: var(--Bld-font-weight);
  
}
.SubSmallTItle14 {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size14);
}
.SubSmallTItle16 {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size16);
  font-weight: var(--Rgl-font-weight);
}
.SubSmallTItle24 {
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size24);
  font-weight: var(--Rgl-font-weight);
}
.BlackBtn {
  border-radius: 4px;
  border: 1px solid var(--dark67);
  background: var(--dark);
  color: var(--white);
}
.BlackBtn:hover, .BlackBtn:active {
  border-radius: 4px;
  border: 1px solid var(--orange);
  background: var(--orange);
  color: var(--white);
}
.GrayBtn {
  border-radius: 4px;
  border: 1px solid var(--dark67);
  background: var(--dark67);
  color: var(--white);
}
.GrayBtn:hover {
  
  border-radius: 4px;
  border: 1px solid var(--dark67);
  background: var(--dark);
  color: var(--white);
}
.OrangeColor {
  color: var(--orange);
}
.LightGrayColor {
  color: var(--lightGray);
}
.WhiteColor {
  color: var(--white) !important;
}
.DarkColor {
  color: var(--dark);
}
.DarkLightColor {
  color: var(--darkLight);
}
.Dark67Color {
  color: var(--dark67);
}
#navbarNav ul.navbar-nav li:nth-child(4) a{border:none; }
.navtop {
  background: linear-gradient(0deg, transparent, #000000);
}
.navbar-brand img {
  height:108px;
}
.navbar-brand {
  margin-right: 0;
  padding: 0 1em;
}
/* .slick-prev {
  left: 10px;z-index:1;
}
.slick-next {
  right: 10px;
} */
.BlackBtn.disabled, .BlackBtn:disabled {
  border-radius: 4px;
  border: 1px solid var(--dark67);
  background: var(--dark67);
  color: var(--white);
}
.mT-10{margin-top: -7px;}
#carouselExampleCaptions .carousel-inner .slick-prev:before{background:green;content:'';background: url(${ArrowLeft}) no-repeat;height:40px; width:40px;position:absolute;}
#carouselExampleCaptions .carousel-inner .slick-next:before{background:red;content:'';background: url(${ArrowRight}) no-repeat;height:40px; width:40px;position:absolute;}
#WhatWeOffer .slick-slider .slick-prev{display: inline-block;
  width: 38px;
  height: 38px; 
  background: var(--dark);
  color: #fff; 
  cursor: pointer;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important; 
  border: solid 1px var(--dark67);
  border-radius: var(--border-radius4); 
  transition: all 0.5s; 
  line-height: 38px;margin-top:32px;display: block;left:90%;z-index:1;bottom:-450px;opacity:1; position:relative;}
  #WhatWeOffer .slick-slider .slick-next{display: inline-block;
  width: 38px;
  height: 38px; 
  background: var(--dark);
  color: #fff; 
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important; 
  border: solid 1px var(--dark67);
  border-radius: var(--border-radius4); 
  transition: all 0.5s;
  text-align: center;
  line-height: 38px;margin-top:32px;display: block; left:96%; z-index:1;bottom:0%; opacity:1;top:100%;position:relative}
#WhatWeOffer .slick-slider .slick-prev:before{background:green;content:'';background: url(${ArrowLeft}) no-repeat;height:30px; width:30px;position:absolute;background-size:40%;left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center bottom;
}
#WhatWeOffer .slick-slider .slick-next:before{background:red;content:'';background: url(${ArrowRight}) no-repeat;height:30px; width:30px;position:absolute;background-size:40%;left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center bottom;
}
#AboutSLIDER .slick-dots{bottom: 10px;}
#AboutSLIDER .slick-slider .slick-prev, #AboutSLIDER .slick-slider .slick-next{
  display: inline-block;
  width: 38px;
  height: 38px; 
  background: var(--dark);
  color: #fff; 
  cursor: pointer;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  border: solid 1px var(--dark67);
  border-radius: var(--border-radius4);
  vertical-align: -0.125em;
  transition: all 0.5s;
  text-align: center;
  line-height: 38px;
  top: 100%;
  margin-top:30px;
}
#AboutSLIDER .slick-slider .slick-prev{left:92.5%;}
#AboutSLIDER .slick-slider .slick-next{left:97%;}
#AboutSLIDER .slick-slider .slick-prev:before{background:green;content:'';background: url(${ArrowLeft}) no-repeat;height:30px; width:30px;position:absolute;background-size:40%;left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center bottom;
}
#AboutSLIDER .slick-slider .slick-next:before{background:red;content:'';background: url(${ArrowRight}) no-repeat;height:30px; width:30px;position:absolute;background-size:40%;left: 0;
  right: 0;
  margin: 0 auto;
  background-position: center bottom;
}
 
.sticky {
  top: 0;
  z-index: 100;
  position: fixed !important;
  /* backdrop-filter: blur(4px); */
  background: linear-gradient(0deg, #3E3E3E, #000000);
  width: 100%;
}
.ssticky {
  top: 88px;
  z-index: 100;
  position: fixed;
  /* backdrop-filter: blur(4px); */
  background: linear-gradient(0deg, #3E3E3E, #000000);
  width: 100%;
}
.sticky .CenterLogo img {
  height:70px;
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}
.navtop {
  width: 100%;
  display: block;
  position: absolute;
  z-index: 9999999;
}
.GlobalContainer {
  background: var(--dark-gradient);
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  overflow: hidden;
}
.GlobalContainerNonBg {
  overflow: hidden;
  padding-top: 60px;
  position: relative;
  padding-bottom: 60px;
}
.GlobalHeading {
  text-align: center;
  position: relative;
  min-height: 124px;
}
.GlobalHeading::before {
  position: absolute;
  content: "";
  height: 1px;
  background: var(--dark67);
  opacity: 0.5;
  width: 8%;
  top: 50%;
  right: 0%;
}
.GlobalHeading:after {
  position: absolute;
  content: "";
  height: 1px;
  background: var(--dark67);
  opacity: 0.5;
  width: 8%;
  top: 50%;
  left: 0%;
}
.GlobalHeading .titleBig {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--dark67);
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size80);
  font-weight: var(--lgt-font-Weight);
  opacity: 0.15;
  will-change: transform;text-transform: uppercase;
  transition: transform 0.2s ease;
}
/* .GlobalHeading .titleBig:hover {
  transform: scaleX(0.8) scaleY(1.2);
} */
.GlobalHeading span {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--white);
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size48);
  font-weight: var(--Bld-font-weight);
  top: 50%;
  z-index: 2;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}
.GlobalHeading h2 {
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  margin: auto;
  color: var(--white);
  font-family:var(--LemonMilk--font-family);
  font-size: var(--font-size48);
  font-weight: var(--Bld-font-weight);
  top: 50%;
  z-index: 2;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  text-transform: uppercase;
}

.slick-slider .element {
  height: 100px;
  width: 100px;
  background-color: #000;
  color: #fff;
  border-radius: 5px;
  display: inline-block;
  margin: 0px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 20px;
}
.slick-slider .slick-disabled {
  opacity: 0;
  pointer-events: none;
}
.ItemContent {
  position: relative;
  height: 374px;
  overflow: hidden;
}
.ItemInfo {
  position: absolute;
  top: 30%;
  padding-left: 10px;
}
.ItemInfo .IconBox {
  border-left: solid 3px var(--orange);
  border-radius: 2px;
  padding-left: 4px;
  margin-left: -10px;
}
.ItemInfo .IconBox img{width:64px !important;}
.ItemInfo p {display:none;}
.itemNavLink {
  position: absolute;
  bottom: 0;
  right: 0;
  background: var(--orange);
  opacity:1;
  height: 42px;
  width: 42px;
  text-align: center;
  font-size: 20px;
  line-height: 42px;display:none;
}
.itemNavLink a {
  color: var(--white);
}
.ItemContent:hover .ItemInfo p {display: block;transition: all 450ms ease-in-out;padding-right:5px;}
.ItemContent:hover .ItemInfo {
  position: absolute;
  top: 10%;
  padding-left: 10px;transition: all 450ms ease-in-out;
}
.ItemContent:hover .itemNavLink{display: block;transition: all 450ms ease-in-out;}
.Head {
  display: block;
  height: 52px;
}
.Head:after {
  content: "";
  display: block;
  clear: both;
}
.Head .Arrows {
  float: right;
  position: relative;
  z-index: 10;
}
.privacy-policy h3 {
  text-transform: uppercase;
}
.privacy-policy {
  color: red;
}

.Head .Slick-Next,
.Head .Slick-Prev {
  display: inline-block;
  width: 38px;
  height: 38px;
  margin-top: 10px;
  background: var(--dark);
  color: #fff;
  margin-left: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: 400 !important;
  font-variant: normal;
  text-transform: none;
  border: solid 1px var(--dark67);
  border-radius: var(--border-radius4);
  vertical-align: -0.125em;
  transition: all 0.5s;
  text-align: center;
  line-height: 38px;
}
.slick-initialized  .slick-slide img{width:100%}
.Head .Slick-Next:hover,
.Head .Slick-Prev:hover {
  background: var(--dark67);
}

.Head .Slick-Next:before {
  content: "\F285";
}

.Head .Slick-Prev:before {
  content: "\F284";
}

.AboutTextBox {
  border-right: solid 1px var(--dark67);
  padding-right: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.AboutImg {
  position: relative;
  min-height: 340px;
}
.AboutImg .AboutImgOne {
  position: absolute;
  z-index: 1;
}
.AboutImg .AboutImgTwo {
  position: absolute;
  right: 0px;
}
.AboutTextBoxEven {
  border-left: solid 1px var(--dark67);
  padding-left: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
}
.AboutImgEven {
  position: relative;
  min-height: 340px;
}
.AboutImgEven .AboutImgOne {
  position: absolute;
  z-index: 1;
  right: 0;
}
.AboutImgEven .AboutImgTwo {
  position: absolute;
  left: 0px;
}

.ProjectBox {
  display: block;
  width: 100%;
}
.ProjectBox .imgBox {
  border-radius: var(--border-radius4);
  position: relative;
  overflow: hidden;
  filter: grayscale(0);
  transition: all 0.5s;
}

.ProjectBox .imgBox span {
  height: 144px;
  width: 144px;
  background: var(--dark);
  border: solid 1px var(--dark67);
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -15%;
  right: -21%;
  z-index: 1;
  padding: 50px 22px;
  font-size: 30px;
  color: #fff;display: none;
  transform: rotate(-45deg);
}
.ProjectBox .imgBox:hover {
  filter: grayscale(1);transition:all 450ms ease-in-out;
}
.ProjectBox .imgBox:hover span {display: block;transition:all 450ms ease-in-out;}
.ProjectBox .TitleBox {
  margin: 10px 0px;
  display: block;
  font-weight: 400;
}
.ProjectBox .TitleBox span {
  float: right;
  font-weight: var(--Rgl-font-weight);
}
.ProjectGallery
{position: relative;
	float: left;
	overflow: hidden;
	margin: 10px 1%; width: 100%;
	background: #3085a3;
	text-align: center;
	cursor: pointer;}

.ProjectGallery .PGImgBox{background: #030c17;}
.ProjectGallery .PGImgBox img {
  position: relative;
	display: block;
	width: 100%;
	opacity: 0.8;
	opacity: 0.9;
	-webkit-transition: opacity 0.35s;
	transition: opacity 0.35s;
}
.ProjectGallery .PGImgBox .PGCaption
{padding: 2em;
	color: #fff;
	text-transform: uppercase;
	
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;}
.ProjectGallery .PGImgBox .PGCaption:before {
	position: absolute;
	top: 30px;
	right: 30px;
	bottom: 30px;
	left: 30px;
	border: 2px solid #fff;
	box-shadow: 0 0 0 30px rgba(255,255,255,0.2);
	content: '';
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale3d(1.4,1.4,1);
	transform: scale3d(1.4,1.4,1);
}
.ProjectGallery .PGImgBox h3 {
	margin: 20% 0 10px 0;background:#00000020;padding:5px;
	-webkit-transition: -webkit-transform 0.35s;
	transition: transform 0.35s;font-size:var(--font-size26); font-family: var(--LemonMilk--font-family);font-weight:var(--font-weight300);
}
.ProjectGallery .PGImgBox h2 span {display:block;width:100%;	opacity: 0;display:none;  }
.ProjectGallery .PGImgBox p {
	padding: 1em;
	opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}
.ProjectGallery .PGImgBox p b{display:block;text-align:center;}
.ProjectGallery .PGImgBox p b a{background:var(--dark);padding:10px;display: inline-block;margin-top: 10px;color:var(--white)}
.ProjectGallery .PGImgBox:hover h2 {
	-webkit-transform: scale(0.9);
	transform: scale(0.9);
}

.ProjectGallery .PGImgBox:hover .PGCaption:before,
.ProjectGallery .PGImgBox:hover p, .ProjectGallery .PGImgBox:hover h2 span {
	opacity: 1;
	-webkit-transform: scale3d(1,1,1);display:block;
	transform: scale3d(1,1,1);
  color: #fff;
}

.ProjectGallery .PGImgBox:hover .PGCaption {
	background-color: rgba(58,52,42,0.8);
}



.WorkStageImg {
  border: solid 1px var(--white);width:100%;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
} 
.WorkStageText {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.WorkStageTextNumber {
  font-size: 158px;
  font-family: var(--LemonMilk--font-family);
 font-weight: var(--Rgl-font-weight);
  color: transparent;
  -webkit-text-stroke: 2px #535353;
}
.WorkStageTextContent {
}
.WorkStageTextContent h4 {
  font-weight:var(--Bld-font-weight);
  padding-bottom: 0px;
  display: block;
}
/* .clientssays .slick-slide{} */
.ClientSay {
  background: var(--dark25);
  border-radius: 8px; 
  padding: 20px;width: 96% !important;
  margin: 14px !important;
}
.clientssays .slick-slider .slick-dots{bottom: -50px !important;}
.ClientSay p {
  margin-top: 10px;height:210px;overflow-y:auto;
}
.ClientSay span {
  margin-top: 0px;
  color: var(--dark67);
  display: block;
}
.ClientSayTitleRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;align-items: center;
}
.ClientSayTitleRow .Profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.ClientSayTitleRow .TestimonialName{ width: 55px !important;
  height: 55px;
  margin-right: 10px;font-size:24px;text-align:center;font-weight:500;line-height:55px;
  border-radius: 50%; color:#fff;}
.ClientSayTitleRow .Profile img {
  width: 55px !important;
  height: 55px;
  margin-right: 10px;
  border-radius: 50%;
}
.ClientSayTitleRow .Profile span {
  color: var(--dark67);
}
.ClientSayTitleRow .Profile span b {
  color: var(--orange);
  font-size: var(--font-size16);
  width: 100%;
  display: block;
}
.ClientSayTitleRow .Rating {
  background: var(--dark);
  border: solid 1px var(--dark67);
  padding: 0px 10px;
  display: flex;
  color: var(--white);
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-radius: 15px;
}
.ClientSayTitleRow .Rating i {
  color: #fbff4d;
  margin-right: 5px;
}
.ClientSayTitleRow .Rating b{padding-left:5px}
.counterBox {
  position: relative;
  width: 100%;
  padding: 20px 0px;
  min-height: 350px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 30px;
  display: block;
}
.CountItem1 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 3px var(--darkLight);
  left: 0%;
  right: 0;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}
.CountItem2 {
  width: 240px;
  height: 240px;
  border-radius: 50%;
  background: var(--dark-rev-gradient);
  
  left:22%;
  right: 0;
  z-index: 1;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}
.CountItem3 {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  border: solid 3px var(--darkLight);
  margin: 0 auto;
  left: 0%;
  right: -17%;
  top: -4%;
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}
.CountItem4 {
  width: 290px;
  height: 290px;
  border-radius: 50%;
  background: var(--dark-rev-gradient);
   
  right: 0%;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}
.CountItem1,
.CountItem2,
.CountItem3,
.CountItem4 {
  position: absolute;
  color: var(--white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.CountItem1 b,
.CountItem2 b,
.CountItem3 b,
.CountItem4 b {
  font-size: 40px;
}

.ContactBox {
  position: relative;
  z-index: 2;
}
.ContactBox .label {
  font-family: var(--LemonMilk--font-family);
  color: var(--white);
  font-size: var(--font-size16);
 font-weight: var(--Rgl-font-weight);
  padding-top: 20px;
  padding-bottom: 0px;
}
.ContactBox .CustomFC {
  color: white;
  /* color: var(--dark67); */
  font-size: var(--font-size16);
  font-weight: 400;
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: solid 1px var(--dark67);
  padding-left: 0px;
}
.ContactBox .CustomFCSel {
  color: white;
  /* color: var(--dark67); */
  font-size: var(--font-size16);
  font-weight: 400;
  border: none;
  border-radius: 0px;
  border-bottom: solid 1px var(--dark67);
  padding-left: 0px;
  background-color: #00000001;
}
.ContactBox select.CustomFCSel option {
  background-color: var(--dark) !important;
  border-radius: 0px;
  padding: 0px 5px !important;
  color: white !important;border:none !important;
} 
.ContactBox .CustomFCSel:focus{box-shadow:none;}
textarea.form-control:focus{outline:0px;box-shadow:none;}

.uploadFile {
  width: 100%;
  background-color: transparent;
  border: 1px solid grey;
  color: grey;
  font-size: 16px;border:none;border-bottom: solid 1px var(--dark67);
  line-height: 23px;
  overflow: hidden;
  padding: 8px 10px 4px 10px;
  position: relative;
  resize: none;
}
.uploadFile[type="file"] {
    cursor: pointer !important;
    display: block;
    font-size: 999px;
    filter: alpha(opacity=0);
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0px;
    text-align: right;
    top: 0px;
    z-index: 1;
  }
  .uploadFile .inputfile{top: 0;
    opacity: 0;
    position: absolute;
  } 
  .uploadFile span{float:right;border: solid 1px var(--dark67);color:var(--white);padding: 2px 15px;
  margin-top: -8px;border-radius:2px;font-size:12px;}
  .uploadFile svg{font-size:var(--font-size24);transform:rotate(45deg);}
.react-tel-input .form-control{
  width: 88%;
  border: 1px solid #CACACA00;
  background: #FFFFFF00;
  color: white;
  /* color: var(--dark67); */
  border-bottom: solid 1px var(--dark67);
  border-radius: 0px;
  height:38px;
  margin-left: 50px;
  padding-left: 10px;
}
.react-tel-input .flag-dropdown{border-bottom: solid 1px var(--dark67);border-radius: 0px;background-color: #f5f5f500;  border: 1px solid #cacaca00;border-bottom: solid 1px var(--dark67);}
.react-tel-input .flag-dropdown:hover, .react-tel-input .selected-flag:hover, .react-tel-input .flag-dropdown.open .selected-flag, .react-tel-input .flag-dropdown.open, .react-tel-input .selected-flag:focus{background:transparent !important;}
.react-tel-input .country-list{max-height:300px !important}
.react-tel-input .country-list .country{margin:5px 0px}
.footerBg {
  background: url(${footerBg}) no-repeat top center;
  padding-top: 60px;
  padding-bottom: 16px;
}
.Fcol span {
  color: var(--orange);
  font-size: var(--font-size20);
  font-weight: bold;
  margin-bottom: 10px;
  display: block;
  font-family: var(--LemonMilk--font-family);
  text-transform:uppercase;
  font-weight: var(--Bld-font-weight);
}
.Fcol ul {
  padding: 0px;
}
.Fcol.get-in-touch-web ul li a svg{margin-right:10px}
.Fcol ul li {
  list-style: none;
  color: var(--white);
  padding: 5px 0px;
}
.Fcol.Fcol2 ul li {
  display: flex;
  margin-right: 30%;
}
.Fcol ul li i {
  margin-right: 10px;
}
.Fcol ul li a {
  color: var(--white);
  text-decoration: none;
}
.Fcol .SocialMediaLink {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.Fcol .SocialMediaLink a {
  color: #fff;
  font-size: 16px;
}
.Finfo {
  background: var(--dark);
  color: var(--white);
  text-align: center;
}
.Finfo ul {
  padding: 0px;
  margin: 0px;
}
.Finfo ul li {
  display: inline-block;
  padding: 0px 6px;
  border-left: solid 1px #fff;
  margin-top: 20px;
  margin-bottom: 20px;
}
.Finfo ul li:first-child {
  border: none;
}
.Finfo ul li a {
  color: #fff;
}
.MobileFooter .accordion-body {
}
.MobileFooter .accordion-body ul {
  padding: 0px;
}
.MobileFooter .accordion-body ul li {
  list-style: none;
  display: flex;
  flex-direction: row;
  color: var(--white);
  padding: 5px 0px;
}
.MobileFooter .accordion-body ul li i {
  margin-right: 10px;
}
.MobileFooter .accordion-body ul li a {
  color: #fff;
  text-decoration: none;
}
.MobileFooter .accordion-body .SocialMediaLink {
  display: flex;
  justify-content: space-between;
  width: 70%;
}
.MobileFooter .accordion-body .SocialMediaLink a {
  color: #fff;
  font-size: 16px;
}
.MobileFooter .accordion-button:focus {
  outline: 0;
  box-shadow: none;
}
.MobileFooter {
  background: url("MFooter.png") no-repeat top center;
  padding-top: 16px;
  padding-bottom: 0px;
}
.MobileFooter .Finfo {
  font-size: 10px;
  background: transparent;
}
.MobileFooter .accordion {
  border: none;
}
.MobileFooter .accordion .accordion-item,
.MobileFooter .accordion .accordion-item:first-of-type {
  border: none;
  background: none;
}
.accordion-item:first-of-type .accordion-button,
.accordion-item .accordion-button {
  background: transparent;
  border-radius: 0px;
  color: var(--orange);
  font-weight: var(--Sbld-font-weight);
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size14);
  border-bottom: solid 1px var(--darkLight);
}
.accordion-button::after {
  filter: invert(1);
}
.accordion-button:not(.collapsed) {
  box-shadow: none;
}
.BgAnimationImg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 12%;
  z-index: -1;
}
.BgAnimationImg .span1 {
  height: 480px;
  width: 480px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 2%;
  left: -15%;box-shadow: none !important;
  transform: translatex(0px);
  animation: floatX 6s ease-in-out infinite;
}
.BgAnimationImg .span2 {
  height: 400px;
  width: 400px;box-shadow: none !important;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  right: -8%;
  top: 20%;
  transform: translatex(0px);
  animation: float 6s ease-in-out infinite;
}
.BgAnimationImg .span3 {
  height: 350px;box-shadow: none !important;
  width: 350px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  left: -8%;
  top: 46%;
  transform: translatex(0px);
  animation: float 6s ease-in-out infinite;
}
.BgAnimationImg .span4 {
  height: 450px;
  width: 450px;box-shadow: none !important;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  right: -15%;
  bottom: 15%;
  transform: translatex(0px);
  animation: floatX 6s ease-in-out infinite;
}
.videoPlay {
  position: relative;
  z-index: 2;
}
.videoPlay .vid {
  position: absolute;
  top: 40%;left:50%;
  -webkit-transform: translate(-50% -50%);
  -ms-transform: translate(-50% -50%);
  transform: translate(-50% -50%);
  z-index: 8; cursor:pointer;
}
.videoPlay .vid .vid-butn:hover .icon {
  color: #fff;
  transform: translate3d(0px, -5px, 0.01px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
}
.videoPlay .vid .vid-butn:hover .icon:before {
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
}
.videoPlay .vid .vid-butn .icon {
  color: var(--white);
  width: 100px;
  height: 100px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  line-height: 100px;
  text-align: center;
  font-size: 40px;
  position: relative;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  display: inline-block;
}
.videoPlay .vid .vid-butn .icon:after {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  border-radius: 50%;
  z-index: -1;
}
.videoPlay .vid .vid-butn .icon:before {
  content: "";
  position: absolute;
  top: 5px;
  bottom: 5px;
  right: 5px;
  left: 5px;
  background-color: rgba(255, 255, 255, 0.1);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  -o-transition: all 0.5s cubic-bezier(1, 0, 0, 1);
  transition: all 0.5s cubic-bezier(1, 0, 0, 1);
}

.BgAnimationImgWall {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 12%;
  z-index: 0;
}
.BgAnimationImgWall .span1 {
  height: 430px;
  width: 430px;box-shadow: none !important;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 2%;
  left: -20%;
  transform: translatex(0px);
  animation: floatX 5s ease-in-out infinite;
}
.BgAnimationImgWall .span2 {
  height: 460px;
  width: 460px;box-shadow: none !important;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  right: -20%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;

  -webkit-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  -moz-box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  box-shadow: 0 20px 30px rgba(0, 0, 0, 0.2),
    inset 0px 10px 30px 5px rgba(255, 255, 255, 1);
  top: 20%;
  transform: translatex(0px);
  animation: float 5s ease-in-out infinite;
}
 
@keyframes float {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatey(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatey(0px);
  }
}
@keyframes floatX {
  0% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatex(0px);
  }
  50% {
    box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2);
    transform: translatex(-20px);
  }
  100% {
    box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6);
    transform: translatex(0px);
  }
}
.slick-dots {
  display: flex !important;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  list-style-type: none;
}
.slick-dots li {
  margin: 0 0.25rem;
}
.slick-dots  li button {
  display: block;
  width: 1rem;
  height: 1rem;
  padding: 0;
  border: none;
  border-radius: 100%;
  background-color: #f47a5740;
  text-indent: -9999px;
  scale: 0.6;
}
.slick-dots li.slick-active button {
  background-color: var(--orange);
  scale: 0.8;
}
.zIndex10 {
  position: relative;
  z-index: 10;
}

/* Inside Page*/
.inSideHeader {
  position: relative;overflow:hidden;
}
.inSideHeader img {
  width: 100%;
}
.inSideHeader .inSideHeaderCaption {
  margin: 0;
  position: absolute;
  top: 65%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  left: 5%;text-transform:uppercase;
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size40);
  font-weight: var(--Bld-font-weight);
  
  padding: 0px;
  text-align: left;
  color: var(--white);
  border-left: solid 3px var(--orange);
  padding-left: 10px;line-height:normal;
}
.inSideHeader .inSideHeaderCaption h1{margin:0px;}
.inSideHeader .inSideHeaderCaption span {
  font-size: var(--font-size24);
  font-weight: var(--Rgl-font-weight);
  display: block;
}
.inSideHeader .inSideHeaderCaption.blog {width:50%}
.AboutSLider {
}
.AboutSLider .ItemBx {
  position: relative;
}
.AboutSLider .ItemBx img {
  width: 100%;
}
.AboutSLider .ItemInfoBox {
  position: absolute;
  top: 25%;
  padding-left: 10px;
  width: 90%;
  margin: 0 auto;
  left: 0;
  right: 0;
}
.AboutSLider:hover .ItemInfoBox {
  position: absolute;
  top: 25%;}
.AboutSLider .ItemInfoBox b {
  font-size: var(--font-size24);
  color: var(--white);
  padding-bottom: 0px;
  border-bottom: solid 2px var(--orange);
  font-family: var(--LemonMilk--font-family);
  display: inline-block;
  font-weight: 400;text-transform:uppercase;
  margin-bottom: 10px;
}
.AboutSLider .ItemInfoBox p {
  color: var(--lightGray);display:block;
  font-size: var(--font-size14);
  line-height: 21px;
}
.philosophyContainer {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0px;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
}
.philosophyText {
  display: none;
}
.philosophyContainer::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.philosophyContainer ul {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0px;
  margin: 0px auto;
}
.philosophyContainer ul li {
  padding: 0px;
  position: relative;
  cursor: all-scroll;
  min-width: 650px;
}
.philosophyContainer ul li img {
  width: 100%;
}
.philosophyContainer ul li:nth-child(2) {
  padding: 20px;
  min-width: 440px;
  color: var(--lightGray);
  font-size: var(--font-size16);
}
.philosophyContainer ul li:nth-child(2) p {
  padding-bottom: 20px;
}
.BgAnimationVision {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  z-index: -1;
}
.BgAnimationVision .span1 {
  height: 1500px;
  width: 1500px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: -50%;
  left: -900px;
  overflow: hidden;
  text-align: right;
}
.BgAnimationVision .span2 {
  height: 600px;
  width: 600px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 20%;
  right: -500px;
}
.BgAnimationVision .span1 .VMContainerImg {
  position: relative;
  top: 50%;
}
.BgAnimationVision .span1 .VMContainerImg img {
  width: 36%;
}
.VisoinMissionBoxImg {
  display: none;
  padding: 0px;
}

.VisoinMissionBox b {
  font-size: var(--font-size32);
  color: var(--white);
 font-weight: var(--Rgl-font-weight);
  font-family: var(--LemonMilk--font-family);
  border-left: solid 2px var(--orange);
  padding-left: 10px;
  margin-bottom: 20px;
  display: block;text-transform:uppercase;
}
.VisoinMissionBox h3 {
  font-size: var(--font-size32);
  color: var(--white);
 font-weight: var(--Rgl-font-weight);
  font-family: var(--LemonMilk--font-family);
  border-left: solid 2px var(--orange);
  padding-left: 10px;
  margin-bottom: 20px;
  display: block;text-transform:uppercase;
}
.VisoinMissionBox p {
  padding-bottom: 0px;font-family:var(--font-Poppins);
  display: block;font-size:var(--font-size16);
}
.VisoinMissionBox ul {
  padding: 0px;margin-bottom:0px;
}
.VisoinMissionBox ul li {
  padding: 10px 0px 5px 0px;
}
.VisoinMissionBox ul li b {
  font-size: var(--font-size20);
  color: var(--orange);
  font-weight: 500;
  font-family: var(--LemonMilk--font-family);
  border: 0px;
  padding-left: 0px;line-height: normal;
  margin-bottom: 0px;
}
.VisoinMissionBox ul li h4 {
  font-size: var(--font-size20);
  color: var(--orange);
  font-weight: 500;
  font-family: var(--LemonMilk--font-family);
  border: 0px;
  padding-left: 0px;line-height: normal;
  margin-bottom: 0px;
}
.SpecialistBox p{font-size:var(--font-size16)}
.SpecialistImg {
  position: relative;
  margin-top: 30px;
}
.SpecialistImg img {
  width: 100%;
  filter: grayscale(1);
}
.SpecialistImg .SpecialistImgText {
  display: flex; 
  position: absolute;
  z-index: 1;
  background: #00000098;
  top: 50%;
  transform: translateY(-50%);
  width: 96%; 
  margin:0px auto;
  left: 0;
  padding: 15px;
  right: 0;
  opacity: 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
.SpecialistImg .SpecialistImgText span {
  color: var(--white);
  width: 30%;
  text-align: left;
}
.SpecialistImg .SpecialistImgText span b {
  display: block;
  font-size: var(--font-size40);
  font-family: var(--LemonMilk--font-family);
  font-weight: 500;text-transform:uppercase;
}
.SpecialistImg .SpecialistImgText span i {
  font-style: normal;
}
.SpecialistImg .SpecialistImgText p {
  width: 100%;color:var(--lightGray);
  text-align: center;line-height:1.3;font-size:15px;
}
.SpecialistImg:hover img,
.SpecialistImg:hover .SpecialistImgText {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.SpecialistImgMobile {
  display: none;
}

/* OurService */
.ServiceIcon ul{padding:0px;display:flex; flex-direction:row;}
.ServiceIcon ul li{width: 16.666666667%;}
.ServiceIcon ul li a{
  display: flex;
  width: 100%;
  flex-direction: column; 
  align-items: center;padding: 0px 38px;
}
.ServiceIcon ul li b{color:#fff;font-size:var(--font-size16); text-align:center;margin-top:10px;font-weight:var(--Rgl-font-weight);font-family:var(--LemonMilk--font-family);line-height:1.2;}

.ServiceIcon ul li h2{color:#fff;font-size:var(--font-size16); text-align:center;margin-top:10px;font-weight:var(--Rgl-font-weight);font-family:var(--LemonMilk--font-family);line-height:1.2;margin-bottom:0px; text-transform:uppercase;}
.ServiceIcon ul li p{opacity: 0;
	-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
	transition: opacity 0.35s, transform 0.35s;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);text-align:center;}
.ServiceIcon ul li:hover b{color:var(--orange);font-weight:var(--Sbld-font-weight);}
.ServiceIcon ul li:hover p{opacity: 1;
	-webkit-transform: scale3d(1,1,1);display:block;
	transform: scale3d(1,1,1);}
/* .ServiceIcon .ServiceIconItems{
  margin: 0px 40px;
  display: flex;
  flex-direction: column; text-align:center;align-items:center;
}

.ServiceIcon .ServiceIconItems img{
 height: 40px;opacity:0.8;
}
.ServiceIcon .ServiceIconItems b{
 font-family: var(--LemonMilk--font-family);
 color: var(--white);
 font-weight: var(--Rgl-font-weight);
}
.ServiceIcon .ServiceIconItems p{
 display: none;
}
  */
.ServiceHeading {
  font-family: var(--LemonMilk--font-family);padding-top: 50px;
  position: relative;
}
.serviceBGElements{position:absolute;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  content: '';
  width: 300px;
  height: 240px;animation: 6s ease-in-out 0s infinite normal none running float;
  top: -15%;right:5%;box-shadow:none !important;
}
.serviceBGElements1{position:absolute;
  background-repeat: no-repeat !important;background-size: 100% !important;box-shadow:none !important;
  content: '';
  width: 300px;
  height: 240px;
  top: -20%;animation: 6s ease-in-out 0s infinite normal none running floatX;
}
.serviceBGLogo{
  position:absolute;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
  box-shadow:none !important;
  content: '';
  width: 110px;
  height: 200px;
  top: -15%;animation: 6s ease-in-out 0s infinite normal none running floatX;
  right: 4%;
}
.ServiceHeading span {
  font-size: 88px;
 font-weight: var(--Rgl-font-weight);
  color: transparent;
  -webkit-text-stroke: 2px #535353;
}
.ServiceHeading b {
  font-size: var(--font-size40);
  font-weight:var(--Bld-font-weight);
  color: var(--orange);
}
.ServiceHeading h2 {
  font-size: var(--font-size40);
  font-weight:var(--Bld-font-weight);
  color: var(--orange);
}
.ServiceHeading b i {
  font-size: var(--font-size20);
  font-weight:var(--Bld-font-weight);
  color: var(--orange);font-style:normal;
}
.ServiceHeading p {
  padding: 40px;
  border: solid 3px var(--darkLight);
  font-family: var(--font-Poppins);
  font-size: var(--font-size16);
  color: var(--lightGray);position:relative; overflow: hidden;
}
.ServiceHeading p:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: rgba(255,255,255,0.4);
	-webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
}
.ServiceHeading p:hover:after {
  width: 120%;
  background-color: rgba(255,255,255,0);
  
	-webkit-transition: all 0.4s ease-in-out;
	   -moz-transition: all 0.4s ease-in-out;
	        transition: all 0.4s ease-in-out;
}
/* .ServiceImage{overflow:hidden;
  -webkit-transition:-webkit-transform 0.35s;
	transition: transform 0.35s;
	-webkit-transform: scale(1.5);
	transform: scale(1.5);
}
.ServiceImage:hover img{
  -webkit-transform: scale3d(1,1,1);display:block;
	transform: scale3d(1,1,1);
} */
.ServiceImage p {
  display: none;
}
.ServiceImage p {
  display: none;
}
.BgAnimationService {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  z-index: 0;
}
.BgAnimationService .span1 {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 5%;
  left: -10%;
  overflow: hidden;
  text-align: right;
}
.BgAnimationService .span2 {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  border: solid 1px var(--darkLight);
  position: absolute;
  bottom: 4%;
  right: -10%;
}
/* Career Page */
.CareersPoint {
  background: var(--dark-gradient);
  padding-top: 30px;
  border-radius: 4px;
  padding-bottom: 30px;
  position: relative;
  color: var(--white);
  overflow: hidden;
  border: solid 1px var(--darkLight);margin-bottom: 40px;
}
.CareersPoint .CareersPointHead {
  color: var(--orange);
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size24);
 font-weight: var(--Rgl-font-weight);
  height: 100%;text-transform:uppercase;
  align-items: center;font-weight:var(--Bld-font-weight);
  display: grid;line-height:normal !important;
}
.CareersPoint .CareersPointHead h3 {
  min-height: 53px;
  font-size: 24px;
  width: 96%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}
.CareersPoint .CareersPointPara{font-family: var(--font-Poppins);font-size: var(--font-size16);color: var(--lightGray);}
.CareersPara h2 {font-size: 20px;
  margin-bottom: 20px;color:var(--lightGray);
}
.CareersPara p {font-size: 20px;
  margin-bottom: 20px;
}
.CareersPara p a{color: var(--orange);}
.openPositionBox{
  padding:10px 15px;
  color: var(--white);
  overflow: hidden;
  border: solid 1px var(--darkLight);margin-bottom: 20px;
  display: flex;flex-direction: column;
}
.openPositionBoxTitle {
border-left: 2px solid var(--orange);padding-left: 5px;margin-bottom: 0px;display: block;position: relative;
}
.openPositionBoxTitle b{color: var(--white);font-family: var(--LemonMilk--font-family);font-size:var(--font-size24);display: block;font-weight: var(--Bld-font-weight);}
.openPositionBoxTitle h3{color: var(--white);font-family: var(--LemonMilk--font-family);font-size:var(--font-size24);display: block;font-weight: var(--Bld-font-weight);}
.openPositionBoxTitle span{font-size: var(--font-size16);color: var(--lightGray);}
.openPositionBoxTitle .GrayBtn{
display: none;
}
.openPositionBoxInfo{display: flex;flex-direction: row;align-items: center;}
.openPositionBoxInfo p{width: 90%;}
.openPositionBoxInfo .BlackBtn {margin: 5px auto;}
/* contact Page */
.contactPara p {
  margin-bottom: 20px;
  font-size: var(--font-size20);
}
.CareersPara p a{
  color: #f47a57;
  margin: 5px;
}
.contactInfo {
  background: var(--dark);
  border: solid 1px var(--darkLight);
  padding: 30px 22px;
  height: 100%;
}
.contactInfo ul {
  margin: 0 auto;
  padding: 0px;
  width: 86%;
}
.contactInfo ul li {
  font-size: 20px;
  padding-bottom: 20px;
  border-bottom: solid 1px var(--darkLight);
  margin-bottom: 20px;
}
.contactInfo ul li b {
  color: var(--orange);
  display: block;text-transform:uppercase;
  font-size: var(--font-size20);
  font-family: var(--LemonMilk--font-family);
 font-weight: var(--Rgl-font-weight);
}
.contactInfo ul li span {
  color: var(--white);
  font-family: var(--font-Poppins);
 font-weight: var(--Rgl-font-weight);
  font-size: var(--font-size16);
}
.contactInfo ul li span a{ color: var(--white);text-decoration:none;}
.contactInfo ul li:last-child {
  border-bottom: none;
  margin-bottom: 0px;
  padding: 0px;
}


.blogThumb {
  margin-bottom: 40px;
  display: block;margin-left:8px;margin-right:8px;
}
.blogThumb .blogThumbImg {
  position: relative;
}
/**/ .blogThumb .blogThumbImg img {
  width: 100%;  
} 
/*.blogThumb .blogThumbImg .blogContent{ 
  padding: 25px 25px 25px 60px;
  max-width: 345px;min-height:120px;  
  position: absolute;
  left: 0;background:#f4f4f4;
  bottom: 20px;
  
}
.blogThumb .blogThumbImg .blogContent b{
  margin-bottom: 0;
  font-size: 22px;
  font-weight: 400;
  line-height: 1.5;
}
.blogThumb a .blogThumbImg .blogContent b{color:#161512}
.blogThumb .blogThumbImg .blogContent span{
  font-size: 14px;
  font-weight: 500;
  writing-mode: vertical-lr;
  position: absolute;
  top: 20px;
  left: 20px;
}
.blogThumb a .blogThumbImg .blogContent span{
color: var(--orange);
}
.blogThumb a:hover .blogContent{background:var(--orange);color:#fff;}
.blogThumb a:hover .blogContent span{color:#fff;}
*/ .blogThumb .blogThumbImg span {
  position: absolute;
  top: 10px;
  left: 10px;
  background: var(--darkLight);
  color: var(--white);
  padding: 10px;
} 
.blogThumb .blogThumbText {
  /* border: solid 1px var(--darkLight); */
  border-top: 0px;
  padding: 10px 0px;
}
.blogThumb .blogThumbText b {
  font-size: var(--font-size20);line-height:normal;
  font-weight: var(--Rgl-font-weight);
  color: var(--white);
  font-family: var(--LemonMilk--font-family);
}
.blogThumb .blogThumbText span {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
}
.blogThumb .blogThumbText span b {
  font-size: var(--font-size14);
  font-family: var(--font-Poppins);
  font-weight: 400;
  font-size: 14px;letter-spacing:0px;
  color: var(--orange);
}
.blogThumb .blogThumbText p {
  color: var(--lightGray);
}
.blogDate {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  right: 5%;
  font-family: var(--font-Poppins);
  font-size: var(--font-size20);
  font-weight: var(--font-weight300);
  padding: 0px;
  text-align: left;
  color: var(--white);
  padding-left: 10px;
}
.BlogDetailText {
  color: var(--white);
}
.BlogDetailText p,
.BlogDetailText p a {
  color: var(--white);
}
.BlogDetailText b {
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size20);
  font-weight: var(--font-weight400);
  color: var(--orange);
  padding: 20px 0px 15px 0px;
  display: block;
}
.BlogDetailText ul {
  padding-left: 0px;
}
.BlogDetailText ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}
.BlogDetailText ul li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  background: var(--orange);
  border-radius: 50%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}
.PrivacyPolicyText {
  color: var(--white);
}
.PrivacyPolicyText p,
.PrivacyPolicyText p a {
  color: var(--white);
}
.PrivacyPolicyText h1, .PrivacyPolicyText h2, .PrivacyPolicyText h3, .PrivacyPolicyText h4, .PrivacyPolicyText h5, .PrivacyPolicyText h6 {
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size20);
  font-weight: var(--font-weight400);
  color: var(--orange);
  padding: 20px 0px 5px 0px;
  display: block;
}
.PrivacyPolicyText b {
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size20);
  font-weight: var(--font-weight400);
  color: var(--orange);
  padding: 20px 0px 15px 0px;
  display: block;
}

.PrivacyPolicyText ul {
  padding-left: 0px;
}
.PrivacyPolicyText ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}
.PrivacyPolicyText ul li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  background: var(--orange);
  border-radius: 50%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}
.PrivacyPolicyText ol {}
.PrivacyPolicyText ol li {
  color: var(--orange);
  position: relative;
  padding-left: 5px;
}
.PrivacyPolicyText ol li b{font-size:var(--font-size18);padding-bottom:0px;}
.PrivacyPolicyText ol li h2{padding: 20px 0px 15px;font-size:var(--font-size18);padding-bottom:0px;margin-bottom:0px;}

.FaqText {
  color: var(--white);
}
.FaqText p,
.FaqText p a {
  color: var(--white);
}
.FaqText b {
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size20);
  font-weight: var(--font-weight400);
  color: var(--orange);
  padding: 20px 0px 15px 0px;
  display: block;
}
.FaqText h1, .FaqText h2, .FaqText h3, .FaqText h4, .FaqText h5, .FaqText h6 {
  font-family: var(--LemonMilk--font-family);
  font-size: var(--font-size20);
  font-weight: var(--font-weight400);
  color: var(--orange);margin-bottom:0px;
  padding: 20px 0px 15px 0px;
  display: block;
}
.FaqText ul {
  padding-left: 0px;
}
.FaqText ul li {
  list-style: none;
  position: relative;
  padding-left: 20px;
}
.FaqText ul li:before {
  content: "";
  position: absolute;
  left: 0;
  width: 8px;
  height: 8px;
  background: var(--orange);
  border-radius: 50%;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0px;
}
.FaqText ol {}
.FaqText ol li {
  color: var(--orange);
  position: relative;
  padding-left: 5px;
}
.FaqText ol li b{font-size:var(--font-size18);padding-bottom:0px;}
/* Projects Page */
.ProjectPageTItle h2{font-family: 'Barokah Signature';font-size:var(--font-size20);color:var(--white);text-align:center; padding-bottom:40px;}
.ProjectPageTItle h2 sup{top: -0.80em;}
.ProjectPage,
.ProjectPageOdd {
  margin-top: 40px;
  padding-bottom: 100px;
  position: relative;
}
.ProjectPage .ProjectImgLeft {
  position: relative;
  text-align: left;
}
.ProjectPage .ProjectImgLeft img {
  width: 400px;
  z-index: 2;left:15%;
  position: relative;
}
.ProjectPage .ProjectImgLeft:before {
  content: '';
  width: 240px;
  height: 550px;
  position: absolute;
  border: solid 1px var(--dark67);
  bottom: -80px;
  left: 0px;
  z-index: 1;
  background: var(--dark-gradient);
}
.ProjectPage .ProjectImgrightCon {
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 1;
}
.ProjectPage .ProjectImgrightCon .ProjectImgText {line-height:1;
  font-size: var(--font-size90);width:100%; text-align: left;
  color: #ffffff08;
  font-weight: var(--font-weight300);
}
.ProjectPage .ProjectImgrightCon .ProjectImgright {
  padding-right: 40px;
}
.ProjectPage .ProjectDetailCon {
  width: 38%;
  padding: 24px;
  border: var(--dark67);
  background: var(--dark-gradient);
  top: 18%;
  position: absolute;
  z-index: 2;
}
.ProjectPage .ProjectDetailCon b {
  font-size: var(--font-size40);
  text-align: center;
  display: block;
  color: var(--white);
  padding-bottom: 0px;
  border-bottom: solid 1px var(--orange);
  font-weight: var(--font-weight400);
  font-family: var(--LemonMilk--font-family);
}
.ProjectPage .ProjectDetailCon h2 {
  font-size: var(--font-size40);
  text-align: center;
  display: block;
  color: var(--white);
  padding-bottom: 0px;
  border-bottom: solid 1px var(--orange);
  font-weight: var(--font-weight400);
  font-family: var(--LemonMilk--font-family);
}
.ProjectPage .ProjectDetailCon p {font-size:var(--font-size16);height:125px;
  overflow: hidden;
  text-overflow: ellipsis;}
.ProjectPage .ProjectDetailCon span {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ProjectPage .ProjectDetailCon span b {
  font-size: var(--font-size16);
  font-family: var(--font-Poppins);
  font-weight: var(--font-weight400);
  color: var(--white);
  border: none;
}

.ProjectPageOdd .ProjectImgLeft {
  position: relative;
  text-align: right;
}
.ProjectPageOdd .ProjectImgLeft img {
  width: 400px;
  z-index: 2;
  position: relative;right: 15%;
}
.ProjectPageOdd .ProjectImgLeft:before {
  content: '';
  width: 240px;
  height: 550px;
  position: absolute;
  border: solid 1px var(--dark67);
  bottom: -80px;
  right: 0px;
  z-index: 1;
  background: var(--dark-gradient);
}
.ProjectPageOdd .ProjectImgrightCon {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
  z-index: 1;
}
.ProjectPageOdd .ProjectImgrightCon .ProjectImgText {
  line-height:1;
  font-size: var(--font-size90);width:100%; text-align: right;
  color: #ffffff08;
  font-weight: var(--font-weight300);
}
.ProjectPageOdd .ProjectImgrightCon .ProjectImgright {
  padding-left: 40px;
}
.ProjectPageOdd .ProjectDetailCon {
  width: 38%;
  padding: 24px;
  border: var(--dark67);
  background: var(--dark-gradient);
  top: 18%;
  position: absolute;
  z-index: 2;
}
.ProjectPageOdd .ProjectDetailCon b {
  font-size: var(--font-size40);
  text-align: center;
  display: block;
  color: var(--white);
  padding-bottom: 0px;
  border-bottom: solid 1px var(--orange);
  font-weight: var(--font-weight400);
  font-family: var(--LemonMilk--font-family);
}
.ProjectPageOdd .ProjectDetailCon h2 {
  font-size: var(--font-size40);
  text-align: center;
  display: block;
  color: var(--white);
  padding-bottom: 0px;
  border-bottom: solid 1px var(--orange);
  font-weight: var(--font-weight400);
  font-family: var(--LemonMilk--font-family);
}

.ProjectPageOdd .ProjectDetailCon p {
  font-size: var(--font-size16); overflow: hidden;height:125px;
  text-overflow: ellipsis;}
.ProjectPageOdd .ProjectDetailCon span {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  padding-bottom: 10px;
}
.ProjectPageOdd .ProjectDetailCon span b {
  font-size: var(--font-size16);
  font-family: var(--font-Poppins);
  font-weight: var(--font-weight400);
  color: var(--white);
  border: none;
}

.ProjectDetailBox {
  margin-top: 40px;
}
.ProjectDetailImg {
  text-align: right;
  position: relative;
}
.ProjectDetailImg img {
  width: 360px;
  position: relative;
  z-index: 2;
}
.ProjectDetailImg:before {
  content: '';
  width: 360px;
  height: 480px;
  position: absolute;
  border: solid 1px var(--dark67);
  top: -20px;
  right: 20px;
  z-index: 1;
  transform: translatey(0px);
  animation: float 5s ease-in-out infinite;
}
.ProjectDetailText {
  color: var(--white);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ProjectDetailText b {
  color: var(--orange);
  display: block;
  font-family: var(--LemonMilk--font-family);
  font-weight: 400;
  margin-bottom: 5px; font-size: var(--font-size20);
  margin-top: 15px;text-transform:uppercase;
}
.ProjectDetailText h3 {
  color: var(--orange);
  display: block;
  font-family: var(--LemonMilk--font-family);
  font-weight: 400;
  margin-bottom: 5px; font-size: var(--font-size20);
  margin-top: 15px;text-transform:uppercase;
}
.ProjectDetailText p {
  margin-top: 15px;
} 
  
 

.BgAnimationProject {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  z-index: -1;
}
.BgAnimationProject .span1 {
  height: 500px;
  width: 500px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: -5%;
  right: -10%;
  overflow: hidden;
  text-align: right;
}
.BgAnimationProject .span2 {
  height: 450px;
  width: 450px;
  border-radius: 50%;
  border: solid 1px var(--darkLight);
  position: absolute;
  bottom: 4%;
  right: -10%;
}

.themeInfoCon {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: end;
}
.themeInfoCon .themeText {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.themeInfoCon .themeText .linkWhite{margin-bottom:40px;}
.themeInfoCon .themeText .themeItem {
  display: flex;
}
.themeInfoCon .themeText .themeItem h4 {
  font-size: 88px;
  font-family: var(--LemonMilk--font-family);
  font-weight: var(--Rgl-font-weight);
  color: transparent;
  -webkit-text-stroke: 2px #535353;
  margin: 0px;
}
.themeInfoCon .themeText .themeItem span {
  color: var(--orange);
  line-height:normal;
  justify-content: end;
  display: flex;
  flex-direction: column;
  padding-bottom: 6px;
}
.themeInfoCon .themeText .themeItem span b {
  display: block;
  font-size: var(--font-size40);
  color: var(--white);
  font-weight: var(--Rgl-font-weight);
}
.themeInfoCon .themeText .themeItem span h2 {
  display: block;
  font-size: var(--font-size40);
  color: var(--white);
  font-weight: var(--Rgl-font-weight);
}
.themeInfoCon .themeThumb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
.themeInfoCon .themeThumb img {
  width: 46%;
}

.BgAnimationTheme {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0%;
  z-index: 0;
}
.BgAnimationTheme .span1 {
  height: 394px;
  width: 394px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 5%;
  left: -12%;
  overflow: hidden;
  text-align: right;
}
.BgAnimationTheme .span2 {
  height: 394px;
  width: 394px;
  border-radius: 50%;
  border: solid 1px var(--darkLight);
  position: absolute;
  bottom: 4%;
  right: -12%;
}

.ThemeMobile .themeItem {
  display: none;
}
 .SitckyBtnSide{
  position: fixed;
    top: 65%;display:block;
    z-index: 100; right: -38px;
    transform: rotate(-90deg);
    background: var(--orange);padding:6px 10px;border-top-right-radius:8px;border-top-left-radius:8px;
 }
 .SitckyBtnSide a{font-family:var(--font-family-LemonMilk);color:var(--white);font-size:var(--font-size18); font-weight:var(--Rgl-font-weight);}
 .MobileInfo{display:none;}
 .diverder{display:none;}
.WebImg{display:block;}
.MobileImg{display:none;}
 .PdfBtnSet{}
 .PdfBtnSet button{background:var(--orange);border:solid 1px var(--orange); margin:0px 10px;color:#fff;}
 .react-tel-input .form-control{width:100% !important;background:transparent !important; border-radius:0px !important;border:0px  !important; border-bottom:solid 1px var(--dark67)!important;padding-left: 32px;}
 .react-tel-input .flag-dropdown{background:transparent !important; border-radius:0px !important;border:0px  !important; border-bottom:solid 1px var(--dark67)!important;}
@media ${DEVICES.desktop}{ 
  /* .nav-item{margin: 0px 30px;} */
  #navbarNav ul.navbar-nav li:nth-child(4) a {
  border: none;display:none;
}
}
@media ${DEVICES.laptop} {

  .ServiceHeading {padding:0px;}
  .ServiceHeading .serviceBGElements{
    width: 180px;
  height: 100%;top: -2%;display:none;
  right: 1%;
  }
  .ServiceHeading .serviceBGElements1{
    width: 180px;display:none;
  height: 100%;top: -2%; 
  }
  .ServiceHeading p{padding:20px;}
  .ServiceHeading span{line-height: 0px;}
  .BgAnimationVision .span1{height: 1000px;
    width: 1000px;
    left: -580px;
    top: -15%;}
    .BgAnimationVision .span1 .VMContainerImg img {
  width: 40%;
}
/* .nav-item { margin: 0px 40px; }  */
  
#WhatWeOffer .slick-slider .slick-next{margin-top: 36px;left: 94%;}
#WhatWeOffer .slick-slider .slick-prev{left: 86%;}
.GlobalHeading span{font-size:50px}
.GlobalHeading .titleBig{font-size:60px;line-height:124px;}
.ClientSayTitleRow .Profile span b{font-size:14px;}
.ClientSayTitleRow .TestimonialName{width:40px !important; height: 40px;font-size: 20px;line-height:40px;}
.ClientSay p {
  margin-top: 10px;height:210px;overflow-y:scroll;
}


}
@media ${DEVICES.tablet} {
  .ServiceHeading {padding:0px;}
  .ServiceHeading .serviceBGElements{
    width: 180px;
  height: 100%;top: -2%;display:none;
  right: 1%;
  }
  .ServiceHeading .serviceBGElements1{
    width: 180px;display:none;
  height: 100%;top: -2%;
  }
  .ServiceHeading p{padding:10px;}
  .ServiceHeading span{line-height: 0px;font-size:54px;} 
  .ServiceHeading b{font-size:30px;}

  .deviceRes.pt-45, .deviceRes.pt-90 {
    padding-top: 0px !important;
  }
  .GlobalHeading span{font-size:40px}
.GlobalHeading .titleBig{font-size:50px;line-height:124px;}
  #WhatWeOffer .slick-slider .slick-next{margin-top: 36px;left: 94%;}
  #WhatWeOffer .slick-slider .slick-prev{left: 86%;}
  .customCaption span{font-size:24px;}
  .navbar-brand img {
  height: 80px;
}
#navbarNav ul.navbar-nav li:nth-child(4) a {
    border: none;
    display: none;
}
  .customCaption{left:10%;}
  .customCaption span{font-size:22px;}
  /* #WhatWeOffer .slick-initialized .slick-slide .ItemInfo .IconBox{width:100px} */
  .navbar-brand img {
  height: 70px;
}

.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 2px;
  width: 25px;
  margin-top: 6px;
  margin-bottom: 6px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(2) {
  opacity: 0;
}
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
} 
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
 .CountItem1 b, .CountItem2 b, .CountItem3 b, .CountItem4 b {
  font-size: 32px;
}
.CountItem1 {
  width: 150px;
  height: 150px;left: 0%;} 
  .CountItem2{ width: 200px;
  height: 200px;left: 20%;}
  .CountItem3 {
  width: 170px;
  height: 170px;}
  .CountItem4{width: 240px;
  height: 240px;right:0%} 
/*.counterBox {
  
 background-color: red;
  }*/
  .AboutSLider .ItemInfoBox{
   top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);}
  .philosophyContainer ul li{min-width: 400px;}
  .philosophyContainer ul li:nth-child(2) {
  padding: 10px;
  min-width: 360px;}
  .philosophyContainer ul li:nth-child(2) p{padding-bottom:10px;}
  .philosophyContainer ul li:nth-child(2) p:last-child{padding-bottom:0px;}
  .BgAnimationVision .VMContainerImg img{display:none;}
  .VisoinMissionBox p {
    padding-bottom: 20px;
  }
  .VisoinMissionBoxImg {
    display: block;
    overflow: hidden;
    margin-bottom: 30px;
  }
  .VisoinMissionBoxImg img {
    width: 100%;
  }
 }
 @media ${DEVICES.mobile} { 
  
 .PdfBtnSet{width:70%; text-align:right}
 .PdfBtnSet button{font-size:0px}
 .PdfBtnSet button svg{font-size:14%}

  .WebImg{display:none;}
.MobileImg{display:block;}
  .itemNavLink{display:block;}
  .navtop {
    
    background: linear-gradient(0deg, #3E3E3E00, #000000);
  position: relative;
}
  .serviceBGElements{display:none;}
  .nav-item {
  align-items: center;
  display: flex;
  margin: 0px 0px;}
  #navbarNav ul.navbar-nav li:nth-child(4) a {
    border: none;
    display: none;
}
  .customCaption{left:10%;}
  .customCaption span{font-size:22px;}
  /* #WhatWeOffer .slick-initialized .slick-slide .ItemInfo .IconBox{width:100px} */
  .navbar-brand img {
  height: 70px;
}
  .navbar-collapse {
  height: 100vh;
  margin-top: 40px;
}
.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 2px;
  width: 25px;
  margin-top: 6px;
  margin-bottom: 6px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(2) {
  opacity: 0;
}
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
}
 
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.GlobalHeading span{font-size:34px}
.GlobalHeading .titleBig{font-size:40px;line-height:124px;}
.AboutTextBox, .AboutTextBoxEven{border: solid 0px var(--dark67);padding:0px}
.diverder {
  display: block;
  height: 1px;
  background: var(--white);
  margin-top: 15px;
}
.deviceRes.pt-45,.deviceRes.pt-90{padding-top:0px !important;}
 
  .ProjectGallery .PGImgBox p{text-transform:capitalize;}
  .ProjectGallery .PGImgBox p b a{padding:5px;}
  .videoPlay .vid .vid-butn .icon{width:70px;height: 70px;line-height:60px;}
  /* .videoPlay .vid .vid-butn .icon svg{width:20px} */


#WhatWeOffer .slick-slider .slick-next,
#WhatWeOffer .slick-slider .slick-prev {
  display: none;
}
#WhatWeOffer .slick-slider{padding-bottom:40px;}
#WhatWeOffer .slick-slider .slick-dots {
  bottom: -10px;
}
.ClientSay{padding:10px;}
.ClientSayTitleRow{flex-direction:column;width:100%;align-items:end;}
.ClientSayTitleRow .Profile{width:98%;justify-content: normal;} 
.ClientSayTitleRow .TestimonialName{width:38px !important;height: 38px;font-size:20px;line-height:38px;}
.counterBox {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  margin: 0px;
}
.CountItem1,
.CountItem2,
.CountItem3,
.CountItem4 {
  position: relative;
}
.CountItem1 b,
.CountItem2 b,
.CountItem3 b,
.CountItem4 b {
  font-size: 32px;
}
.CountItem1 span,
.CountItem2 span,
.CountItem3 span,
.CountItem4 span {
  font-size: 12px;
}
.CountItem1,
.CountItem2 {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.CountItem1,
.CountItem3 {
  width: 140px;
  height: 140px;
}
.CountItem2 {
  width: 175px;
  height: 175px;
}
.CountItem4 {
  right: 0;
  top: -5%;
  width: 210px;
  height: 210px;
  left: 45%;
}
.CountItem3 {
  left: -10%;
}
.CountItem1 {
  left: -5%;
}
.Fcol1 .footerLogo {
  width: 80px !important;
}
.Finfo ul li {
    margin-top: 0px;
    margin-bottom: 10px;
    font-size: 14px;
  }
  .MobileFooter .accordion-body .SocialMediaLink{width: 50%;
  margin: 0 auto;}
  .WorkStageText {
  flex-direction: column;
  align-items: start;
  margin-bottom: 40px;
}
.WorkStageTextNumber {
  font-size: 80px;
  flex-direction: row;
  display: flex;
  align-items: end;
  line-height: 0.8;
  margin-bottom: 30px;
}
.WorkStageTextNumber span {
  -webkit-text-stroke: 0px var(--orange);
  font-size:54px !important;
  color: var(--orange);
  margin-left: 10px;
  padding-bottom: 10px;
  font-weight: 400; 
}

 }
 @media ${DEVICES.sMobile} {  
    
 .PdfBtnSet{width:70%; text-align:right}
 .PdfBtnSet button{font-size:0px;padding:10px;margin: 0px 6px;}
 .PdfBtnSet button svg{font-size:18px; }
  .navtop {
    background: linear-gradient(0deg, #3E3E3E00, #000000);
    position: absolute;
  }
.sticky {
  top: 0px; background: linear-gradient(0deg, #3E3E3E, #000000);
}
.sticky button {
  
}
.StopScroll .navtop{background: linear-gradient(0deg, #3E3E3E, #000000);}
/* .navbar{background:var(--dark-rev-gradient)} */
.navbar-toggler {
  border: none;
}
ul.navbar-nav li:nth-child(4) {
  display: none;
}
.navbar-collapse {
  height: 100vh !important;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
}
.navbar-toggler span {
  display: block;
  background-color: #fff;
  height: 2px;
  width: 25px;
  margin-top: 6px;
  margin-bottom: 6px;
  position: relative;
  left: 0;
  opacity: 1;
  transition: all 0.35s ease-out;
  transform-origin: center left;
}
.navbar-toggler span:nth-child(1) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(2) {
  opacity: 0;
}
.navbar-toggler span:nth-child(3) {
  transform: translate(0%, 0%) rotate(0deg);
}
.navbar-toggler span:nth-child(1) {
  margin-top: 0.3em;
}
.navbar-toggler:not(.collapsed) span:nth-child(1) {
  transform: translate(15%, -33%) rotate(45deg);
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  opacity: 0;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  transform: translate(15%, 33%) rotate(-45deg);
} 
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0;
}
.navbar {
  padding: 5px 0px;
  height: 100%;
    align-content: start;
}
.navbar-brand img {
  height: 70px;
}

.navbar-nav {
  align-items: center;flex-direction: column !important;
}
.navbar .navbar-nav .nav-link {
  color: #fff;
  font-size: 18px;
  padding: 0.5em 1em;
  font-family: var(--font-family-LemonMilk);
}
.navbar-expand-md .navbar-nav {
  flex-direction: column;
}
  .serviceBGElements, .serviceBGElements1{display:none;}
  .mobileAcc .accordion-button:not(.collapsed)::after {
  display: none;
}
.get-in-touch-web {
  display: none;
}
.get-in-touch-mobile {
  display: block;
}
.contactPara p {
  font-size: var(--font-size14);
}
.diverder {
  display: block;
  height: 1px;
  background: var(--white);
  margin-top: 15px;
}
.SitckyBtnSide {
  display: none;
}
.contactInfo ul {
  width: 100%;
}
.MoblieSwitch {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #ffffff60;
  color: #3e3e3e80;
  font-size: 20px;
  padding: 10px 15px;
  transform: rotateY(180deg);
}

#WhatWeOffer {
  padding-bottom: 40px;
}
#WhatWeOffer .slick-slider .slick-dots {
  bottom: -40px;
}


#WhatWeOffer .slick-slider .slick-next,
#WhatWeOffer .slick-slider .slick-prev {
  display: none;
}
.OProjects .pt-45,
.OProjects .pt-90 {
  padding-top: 0px !important;
}
.ProjectBox {
  margin-bottom: 60px;
}

.customCaption {
  bottom: 30%;
  font-size: 40px;
}

.AboutImg,
.AboutImgEven {
  min-height: 210px;
}
.AboutImg img,
.AboutImgEven img {
  width: 80%;
}
.AboutImgTwo,
.AboutImgEven .AboutImgOne {
  text-align: right;
}
.AboutTextBox,
.AboutTextBoxEven {
  border: none;
  padding: 0px;
}
.AboutImgEven .AboutImgTwo {
  text-align: left;
}
.pb-10 {
  padding-bottom: 5px !important;
}
.GlobalHeading {
  min-height: 64px;
  margin-bottom: 20px;
}

.BlackBtn {
  margin-bottom: 40px;
}
.BlackBtn.float-end {
  float: left !important;
}
.responsive .slick-list {
  padding: 0 30% 0 0 !important;
}
.responsive .slick-slide {
  scale: 0.92;
}
.responsive .slick-slide.slick-current {
  scale: 1;
}
.ItemInfo {
  padding: 0px 10px;
  top: 25%;
}
.ItemInfo .SubSmallTItle.ptb-15 {
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
.ItemInfo p {
  display: block;
}
.SubSmallTItle24 {
  line-height: normal;
}
/**/
.GlobalHeading .titleBig {
  font-size: 32px;line-height:68px;
}
.GlobalHeading span {
  width: 100%;font-size: 24px; 
}
.counterBox {
  min-height: 100%;
  display: flex;
  flex-direction: column-reverse;
  margin: 0px;
}
.CountItem1,
.CountItem2,
.CountItem3,
.CountItem4 {
  position: relative;
}
.CountItem1 b,
.CountItem2 b,
.CountItem3 b,
.CountItem4 b {
  font-size: 32px;
}
.CountItem1 span,
.CountItem2 span,
.CountItem3 span,
.CountItem4 span {
  font-size: 12px;
}
.CountItem1,
.CountItem2 {
  left: 0;
  right: 0;
  margin: 0 auto;
}
.CountItem1,
.CountItem3 {
  width: 140px;
  height: 140px;
}
.CountItem2 {
  width: 175px;
  height: 175px;
}
.CountItem4 {
  right: 0;
  top: 0;
  width: 210px;
  height: 210px;
  left: 22%;
}
.CountItem3 {
  left: -10%;
}
.CountItem1 {
  left: -5%;
}
.BgAnimationImg .span1 {
  width: 212px;
  height: 212px;
  top: 3%;
}
.BgAnimationImg .span2 {
  width: 146px;
  height: 146px;
  top: 28%;
}
.BgAnimationImg .span3 {
  width: 280px;
  height: 280px;
  top: 50%;
  left: -25%;
}
.BgAnimationImg .span4 {
  width: 226px;
  height: 226px;
  bottom: 20%;
}
.BgAnimationImgWall .span1 {
  width: 200px;
  height: 200px;
  left: -31%;
}
.BgAnimationImgWall .span2 {
  width: 295px;
  height: 295px;
  opacity: 0.5;
  right: -50%;
}
.BgAnimationImgWall .span2::after {
  width: 295px;
  height: 295px;
  opacity: 0.5;
}
.linkWhite {
  margin-top: 20px;
  display: inline-block;
}
.WorkStageText {
  flex-direction: column;
  align-items: start;
  margin-bottom: 40px;
}
.WorkStageTextNumber {
  font-size: 104px;
  flex-direction: row;
  display: flex;
  align-items: end;
  line-height: 0.8;
  margin-bottom: 30px;
}
.WorkStageTextNumber span {
  -webkit-text-stroke: 0px var(--orange);
  font-size: 24px !important;
  color: var(--orange);
  margin-left: 10px;
  padding-bottom: 10px;
  font-weight: 400;
  line-height: normal;
}
.Fcol1 .footerLogo {
  width: 100px !important;
}
.ClientSay {
  margin: 7px !important;
}

.ProjectBox .imgBox img {
  width: 100%;
}
.inSideHeader img {
  /* height: 100vh; */
  width: 100%;
  text-align: center;
  position: relative;
}
.AboutSLider {
}
.AboutSLider .ItemBx {
  position: relative;
}
.AboutSLider .ItemBx img {
  width: auto;
  height: 56vh;
}
.AboutSLider .ItemInfoBox {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.AboutSLider:hover .ItemInfoBox {
  position: absolute;
  top: 50%;
}
#AboutSLIDER .slick-slider .slick-prev,
#AboutSLIDER .slick-slider .slick-next {
  display: none !important;
}
#AboutSLIDER .slick-dots {
  bottom: -50px;
}
.Head {
  display: none;
}
.philosophyText {
  display: block;
  margin-bottom: 40px;
}
.philosophyContainer ul li {
  min-width: 320px;
}
.philosophyContainer ul li:nth-child(2) {
  display: none;
}
.VisoinMissionBox p {
  padding-bottom: 20px;
}
.VisoinMissionBox ul li p {
  padding-bottom: 0px;
}
.VisoinMissionBoxImg {
  display: block;
  overflow: hidden;
  margin-bottom: 30px;
}
.VisoinMissionBoxImg img {
  width: 100%;
}
.BgAnimationVision {
  display: none;
}
.Mleft {
  text-align: left !important;
}
.SpecialistImg {
  display: none;
}
.SpecialistImgMobile {
  display: block;
}
.SpecialistImgMobile {
  position: relative;
}
.SpecialistImgMobile .Spimgbx {
  position: relative;
  margin-top: 30px;
}
.SpecialistImgMobile .Spimgbx img {
  width: 100%;
}
.SpecialistImgMobile .Spimgbx span {
  color: var(--white);
  width: 90%;
  display: block;
  position: absolute;
  bottom: 5%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background: #1d1d1d35;
  text-align: center;
  padding: 10px;
}
.SpecialistImgMobile .Spimgbx span b {
  display: block;
  font-size: var(--font-size40);
  font-family: var(--LemonMilk--font-family);
  font-weight: 500;
  text-transform: uppercase;
}
.SpecialistImgMobile .Spimgbx span i {
  font-style: normal;
}
.SpecialistImgMobile p {
  width: 100%;
  text-align: left;
  padding: 10px;
  background: var(--dark-rev-gradient);
}
.SpecialistImgMobile:hover img,
.SpecialistImgMobile:hover .SpecialistImgMobileText {
  filter: grayscale(0);
  opacity: 1;
  transition: all 0.5s ease-in-out;
}
.Finfo ul li {
  margin-top: 0px;
  margin-bottom: 10px;
  font-size: 14px;
}
.Finfo ul li:first-child {
  border: none;
  width: 100%;
  display: block;
}
.Finfo ul li:nth-child(2) {
  border: none;
}
/* service page */
.ServiceIcon {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 0px;
  box-sizing: border-box;
  overflow: auto;
  scrollbar-width: none;
}

.ServiceIcon::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
.ServiceIcon ul {
  display: flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  list-style: none;
  padding: 0px;
  margin: 0px auto;
}
.ServiceIcon ul li {
  padding: 0px;
  position: relative;
  cursor: all-scroll;
  min-width: 120px;
}

.ServiceHeading {
  text-align: left !important;
  display: flex;
  align-items: end;
}
.ServiceHeading span {
  font-size: 104px;
  font-weight: var(--Rgl-font-weight);
  color: transparent;
  -webkit-text-stroke: 2px #535353;
  line-height: 1;
}
.ServiceHeading b {
  font-size: 30px;
  font-weight: 500;
  line-height: normal;
  padding-bottom: 20px;
  margin-left: 10px;
  color: var(--orange);
}
.ServiceHeading p {
  padding: 40px;
  border: solid 3px var(--darkLight);
  font-family: var(--font-Poppins);
  font-size: var(--font-size16);
  color: var(--lightGray);
  display: none;
}
.ServiceImage p {
  display: block;
  padding: 10px;
  border: solid 3px var(--darkLight);
  font-family: var(--font-Poppins);
  font-size: var(--font-size16);
  color: var(--lightGray);
  margin: -3px 20px 0px 20px;
  position: relative;
  z-index: -1;
}
.BgAnimationService .span1 {
  height: 230px;
  width: 230px;
  border-radius: 50%;
  border: solid 1px var(--dark67);
  position: absolute;
  top: 5%;
  left: -10%;
  overflow: hidden;
  text-align: right;
}
.BgAnimationService .span2 {
  height: 230px;
  width: 230px;
  border-radius: 50%;
  border: solid 1px var(--darkLight);
  position: absolute;
  bottom: 4%;
  right: -10%;
}

.CareersPara p {
  font-size: 14px;
}
.CareersPoint {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.CareersPoint .CareersPointHead {
  min-height: 53px;
  font-size: 24px;
  width: 96%;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.CareersPoint .CareersPointHead.bdrRdarkLight {
  border-right: 0px;
  border-bottom: solid 1px var(--darkLight);
  padding-bottom: 5px;
  margin-bottom: 5px;
  display: block;
}
.CareersPoint .CareersPointHead.bdrLdarkLight {
  border-right: 0px;
  border-bottom: solid 1px var(--darkLight);
  padding-bottom: 5px;
  margin-bottom: 5px;
  justify-content: end;
  border-left: 0px;
  padding-right: 5px !important;
}
.CareersPoint .CareersPointHead.pl-20 {
  padding-left: 5px !important;
}
.CareersPoint .CareersPointPara.pr-20 {
  padding-right: 0px !important;
  width: 96%;
  margin: 0 auto;
  padding-top: 10px;
}
.CareersPoint .CareersPointPara {
  width: 96%;
  margin: 0 auto;
  padding-top: 20px;padding-bottom:20px;
}
.CareersPoint .CareersPointPara.pl-20 {
  padding-left: 0px !important;
}
.openPositionBoxTitle .GrayBtn {
  position: absolute;
  right: 0;
  top: 0;
  display: block;
  margin: 0px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 0px;
  background: #ffffff60;
}
.openPositionBoxTitle b {
  font-size: 16px;
  color: var(--white);
}
.openPositionBoxInfo p {
  width: 100%;
}
.openPositionBoxInfo .BlackBtn {
  display: none;
}
.blogThumb {
  margin-bottom: 40px;
  display: block;
}
.blogThumb .blogThumbImg {
  position: relative;
}
.blogThumb .blogThumbImg img {
  width: 100%;
}
.blogThumb .blogThumbImg span {
  position: absolute;
  top: 10px;
  left: 10px;
  background: var(--darkLight);
  color: var(--white);
  padding: 10px;
}
.blogThumb .blogThumbImg{text-align:center;}
  .blogThumb .blogThumbImg img{width:96%;}
  .blogThumb .blogThumbText b{font-size:18px;}
.blogThumb .blogThumbText {
  width: 86%;
  margin: 0 auto; 
}
.blogThumb .blogThumbText p {
  color: var(--lightGray);
  display: none;
}
.BlogDetailText ul li {
  display: block;
  margin-bottom: 10px;
}
.BlogDetailText ul li:before {
  top: 10px;
}
.PrivacyPolicyText ul li {
  display: block;
  margin-bottom: 10px;
}
.PrivacyPolicyText ul li:before {
  top: 10px;
}
.PrivacyPolicyText p a {
  color: var(--orange);
  text-decoration: none;
}
:root {
  --font-size16: 14px;
  --font-size48: 30px;
  --font-size32: 28px;
  --font-size90: 32px;
  --font-size24: 22px;
  --font-size40: 30px;
}
.CareerHeadImg {
  margin-left: -690px;
}
.MobileInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: solid 1px #676767;
  padding-top: 20px;
  margin-top: 10px;
  font-size: 16px;
}
.MobileInfo a {
  color: #fff;
  text-decoration: none;
  padding: 0px 0px 5px 0px;
}
.MobileInfo a svg{margin-right:10px;}
.MobileInfo a.MmenuGQbtn{
  background: var(--orange);padding: 4px 10px;border-top-left-radius: 8px;border-bottom-right-radius: 8px;margin-top: 10px;}
#collapseFive .accordion-body ul li a svg{margin-right:10px} 
/* Projects Page StartMobile */
.ProjectPageTItle {
  font-size: var(--font-size14);
  line-height: normal;
}
.ProjectPage .ProjectDetailCon {
  width: 90%;
  top: 25%;
  left: 10px;
}
.ProjectPage .ProjectDetailCon h2 {
  width: 90%;
  top: 25%;
  left: 10px;
}
.ProjectDetailCon .BlackBtn {
  margin-bottom: 0px;
}
.ProjectPage .ProjectImgLeft:before,
.ProjectPageOdd .ProjectImgLeft:before {
  display: none;
}
.ProjectPage .ProjectImgLeft {
  text-align: right;
  right: -5px;
}
.ProjectPage .ProjectImgLeft img {
  width: 90%;
  left: auto;
}
.ProjectPage .ProjectImgrightCon {
  display: block;
}
.ProjectPage .ProjectImgrightCon .ProjectImgText {
  position: absolute;
  top: -172%;
  font-size: 40px !important;
  width: 90%;
  margin-left: 10%;
  text-align: left;
  color: #ffffff29;
}
.ProjectPage .ProjectImgrightCon .ProjectImgright {
  padding-right: 0px;
  padding-top: 40px;
  text-align: right;
  right: -5px;
  position: relative;
}
.ProjectPage .ProjectImgrightCon .ProjectImgright img {
  width: 90%;
}

.ProjectPageTItle {
  font-size: var(--font-size14);
  line-height: normal;
}
.ProjectPageTItle h2{
  font-size: var(--font-size14);
  line-height: normal;
}
.ProjectPageOdd .ProjectDetailCon {
  width: 90%;
  top: 25%;
  right: 10px;
}
.ProjectDetailCon .BlackBtn {
  margin-bottom: 0px;
}
.ProjectPage .ProjectImgLeft:before,
.ProjectPageOdd .ProjectImgLeft:before {
  display: none;
}
.ProjectPageOdd .ProjectImgLeft {
  text-align: left;
  left: -5px;
}
.ProjectPageOdd .ProjectImgLeft img {
  width: 90%;
  right: auto;
}
.ProjectPageOdd .ProjectImgrightCon {
  display: block;
}
.ProjectPageOdd .ProjectImgrightCon .ProjectImgText {
  position: absolute;
  top: -172%;
  text-align: right;
  font-size: 40px !important;
  width: 90%;
  margin-right: 10%;
  color: #ffffff29;
}
.ProjectPageOdd .ProjectImgrightCon .ProjectImgright {
  padding-left: 0px;
  padding-top: 40px;
  text-align: left;
  left: -5px;
  position: relative;
}
.ProjectPageOdd .ProjectImgrightCon .ProjectImgright img {
  width: 90%;
}

/* Projects Page End */
.themeInfoCon .themeText {
  width: 100%;
  display: none;
}
.ThemeMobile .themeItem {
  display: flex;
  align-items: end;
  margin-bottom: 10px;
}
.ThemeMobile .themeItem h4 {
  font-size: 88px;
  margin-bottom: 0px;
  font-family: var(--LemonMilk--font-family);
  font-weight: var(--Rgl-font-weight);
  color: transparent;
  -webkit-text-stroke: 2px #535353;
  line-height: 1;
}
.ThemeMobile .themeItem span {
  color: var(--orange);
  line-height: 1;
  justify-content: center;
  display: flex;
  flex-direction: column;
}
.ThemeMobile .themeItem span b {
  display: block;
  font-size: var(--font-size40);
  color: var(--white);
  font-weight: var(--Rgl-font-weight);
  line-height: normal;
  padding-bottom: 10px;
}

.ThemeMobile .ProjectImgLeft img {
  width: 90%;
  z-index: 2;
  position: relative;
}
.active {
  display: none !important;
}
.accordion-item:first-of-type .accordion-button,
.accordion-item .accordion-button {
  font-size: var(--font-size18);
}

.ProjectGallery .PGImgBox .PGCaption:before,
.ProjectGallery .PGImgBox p, .ProjectGallery .PGImgBox h2 span {
	opacity: 1;
	-webkit-transform: scale3d(1,1,1);display:block;
	transform: scale3d(1,1,1);
  color: #fff;
}
.ProjectGallery .PGImgBox .PGCaption {
	background-color: rgba(58,52,42,0.8);
}
.ProjectGallery .PGImgBox p b a svg{font-size:28px}

}
/*hover on images*/
.hover-effect:before,
.hover-effect:after {
   content: "";
   background: #fff;
   height: 0;
   width: 0;
   z-index: 1;
   position: absolute;
   -webkit-transition-duration: 1.3s;
	-o-transition-duration: 1.3s;
	transition-duration: 1.3s;
}
.hover-effect:before {
	right: 0;
	opacity: 1;
	top: 0;
}
.hover-effect:after {
	bottom: 0;
	opacity: .7;
	left: 0;
}
.hover-effect:hover:after, .hover-effect:hover:before {
	height: 100%;
	opacity: 0;
	width: 100%;
}
iframe.ytp-impression-link{display:none !important;opacity:0 !important;}
iframe.ytp-show-cards-title{display:none !important;opacity:0 !important;}

.gallery-images {
  position: relative;
  padding-bottom: 45%;
  overflow: hidden;
}
  
.gallery-images .image-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.after-image, .before-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 12px;
  transition: opacity 0.5s ease;
}

.after-image {
  opacity: 1;
  z-index: 1;
}

.before-image {
  opacity: 0;
  z-index: 2;
}

.gallery-images:hover .after-image {
  opacity: 0;
}

.gallery-images:hover .before-image {
  opacity: 1;
}

.brochureSlideDiv {
  margin: 0px 15px;
  height: 300px !important;
  width: 350px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.brochureImg {
  border-radius: 10px 10px 0 0;
  border-radius: 12px;
}

.brochure-heading {
  margin: 10px 0;
  font-size: var(--font-size14);
  letter-spacing: 1px;
  /* font-weight: 800; */
  color: white;
}

.brochure-btn-div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 16px;
}

.brochure-btn-1 {
  background: transparent;
  border: none;
  color:white;
  font-size: var(--font-size16);
  border: 1px solid #808080;
  border-radius: 4px;
}

.brochure-btn-2 {
  background: var(--orange);
  border: none;
  color:white;
  font-size: var(--font-size16);
  border-radius: 4px;
}

.outdoorServiceImage {
  position: relative;
  overflow: hidden;
}

.outdoorServiceImage .white-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 90px;
  height: 90px;
  padding: 10px;
  border-bottom-right-radius: 10px;
  background: rgba(255, 255, 255, 0.2);
  z-index: 2;
  transition: none;
  animation: none;
}

.outdoorServiceImage.logo-hover:hover .white-logo {
  animation: expandLogo 1.5s ease-in-out forwards;
}

.outdoorServiceImage .white-logo:not(:hover) {
  animation: contractLogo 1.5s ease-in-out forwards;
}

@keyframes expandLogo {
  0% {
    width: 90px;
    height: 100px; 
    top: 0;
    left: 0;
    padding: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
  }
  100% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 25%;
    border-bottom-right-radius: 0;
    background: rgba(0, 0, 0, 0.4);
  }
}

@keyframes contractLogo {
  0% {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    padding: 25%;
    border-bottom-right-radius: 0;
    background: rgba(0, 0, 0, 0.4);
  }
  100% {
    width: 90px;
    height: 100px;
    top: 0;
    left: 0;
    padding: 10px;
    border-bottom-right-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
  }
}

@media ${DEVICES.tablet} {
  .brochureSlideDiv {
    margin: 0px 5px;
    height: 250px !important;
    width: 350px !important;
  }
  .serviceBGLogo{
    display: none;
  }
}

@media ${DEVICES.mobile} {
  .brochureSlideDiv {
    margin: 0px 15px;
    height: 260px !important;
    width: 280px !important;
    margin-bottom: -120px;
  }
  .brochure-heading {
    margin: 7px 0;
    font-size: var(--font-size16);
  }

  @keyframes expandLogo {
    0% {
      width: 90px;
      height: 100px; 
      top: 0;
      left: 0;
      padding: 10px;
      border-bottom-right-radius: 10px;
      background: rgba(255, 255, 255, 0.2);
    }
    100% {
      width: 100%;
      height: auto;
      top: 0;
      left: 0;
      padding: 25%;
      border-bottom-right-radius: 0;
      background: rgba(0, 0, 0, 0.4);
    }
  }

  .serviceBGLogo{
    position:absolute;
    background-repeat: no-repeat !important;
    background-size: 100% !important;
    box-shadow:none !important;
    content: '';
    width: 80px;
    height: 200px;
    top: -15%;animation: 6s ease-in-out 0s infinite normal none running floatX;
    right: 4%;
  }
}
`;

export const StyledLayoutWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const StyledContainer = styled.div`
  width: 100%;
`;

export const StyledContent = styled.div`
  min-height: 450px;
`;
