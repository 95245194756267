import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import blogPageDataService from '../services/BlogService';
import { BlogActionTypes, blogPageAction } from '../store/slices/blogSlice';

// saga handler to fetch About Us sectionwise and update redux store as per the api payload
export const fetchBlogSection = function* (action) {
  try {
    // yield put(startAction());
    const response = yield call(
      blogPageDataService.getBlogPageData,
      action.payload
    );
    const responseData = yield get(response, 'data');
    yield put(
      blogPageAction.setBlogPageSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );
    // yield put(endAction());
  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  }
};

export default function* BlogSaga() {
  yield takeLatest(BlogActionTypes.FETCH_BLOG_PAGE_DATA, fetchBlogSection);
}
