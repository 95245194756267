import {
  FaFacebookF,
  FaYoutube,
  FaXTwitter,
  FaInstagram,
  FaTiktok,
  FaLinkedinIn,
  FaWhatsapp,
  FaLocationDot,
} from 'react-icons/fa6';
import { BsTelephoneFill, BsEnvelopeFill } from 'react-icons/bs';
import { PAGE_URL } from '../../config/appConfig';

export const socialLinks = [
  {
    icon: <FaFacebookF />,
    href: 'https://www.facebook.com/OutMazedDesign',
    cmsUrl: 'SiteFacebookURL',
  },
  {
    icon: <FaInstagram />,
    href: 'https://www.instagram.com/outmazeddesign/',
    cmsUrl: 'SiteInstagramURL',
  },
  {
    icon: <FaXTwitter />,
    href: 'https://twitter.com/OutMazedDesign',
    cmsUrl: 'SiteTwitterURL',
  },
  {
    icon: <FaYoutube />,
    href: 'https://www.youtube.com/@OutmazedDesign',
    cmsUrl: 'SiteYoutubeURL',
  },
  { icon: <FaLinkedinIn />, href: '#', cmsUrl: 'SiteLinkedInURL' },
  {
    icon: <FaWhatsapp />,
    href: 'https://wa.me/97143399683',
    cmsUrl: 'SiteWhatsappURL',
  },
  { icon: <FaTiktok />, href: '', cmsUrl: 'SiteTikTokURL' },
];

const getFooterDetails = () => {
  return [
    {
      detailName: 'COMPANY',
      class: 'Fcol2',
      subHeading: [
        { label: 'About Us', href: PAGE_URL.ABOUT },
        { label: 'Projects', href: PAGE_URL.PROJECTS },
        { label: 'Themes', href: PAGE_URL.THEME },
        { label: 'Careers', href: PAGE_URL.CAREERS },
        { label: 'Blogs', href: PAGE_URL.BLOGS },
        { label: 'Contact Us', href: PAGE_URL.CONTACT_US },
      ],
    },
    {
      detailName: 'SERVICES',
      class: 'Fcol3',
      subHeading: [],
    },
    {
      detailName: 'PROJECTS',
      btnClass: 'get-in-touch-mobile',
      class: 'Fcol3',
      subHeading: [],
    },
    {
      detailName: 'THEMES',
      btnClass: 'get-in-touch-mobile',
      class: 'Fcol3',
      subHeading: [],
    },
  ];
};
export default getFooterDetails;
