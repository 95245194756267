// Keep all the api related configs such as endpoints in this file
// const baseUrl = 'https://isourse.com:1047/'; //production api's
// const baseUrl = 'https://demo.isoping.com:7344'; //test api's
// const baseUrl = 'https://192.168.222.40:6642'; //development api's
const baseUrl = 'https://dashboard.outmazeddesign.com/api'; //local api's

// API key 
export const apiKey = process.env.REACT_APP_API_TOKEN;

// Endpoints for getting cms data
export const GET_API_ENDPOINTS = {
  SECTIONWISE: `${baseUrl}/GetSectionWise`,
  GET_AREAS: `${baseUrl}/ContactUs/Area/Get`,

};

export const POST_API_ENDPOINTS = {
  ADD_QUERIES: `${baseUrl}/ContactUs/Queries/Add`,
  ADD_APPLICATIONS: `${baseUrl}/Careers/Section4/Add`,
};
export const OMD_API_ENDPOINTS = {
  HOME_PAGE: `${baseUrl}/home-page`,
  SERVICE_PAGE: `${baseUrl}/service-page`,
  PROJECT_PAGE: `${baseUrl}/project-page`,
  ABOUT_US_PAGE: `${baseUrl}/about-us-page`,
  CAREER_PAGE: `${baseUrl}/career-page`,
  CONTACT_PAGE: `${baseUrl}/contact-us-page`,
  FAQ_PAGE: `${baseUrl}/faq-page`,
  TERMS_PAGE: `${baseUrl}/terms-page`,
  PRIVACY_PAGE: `${baseUrl}/privacy-page`,
  THEME_PAGE: `${baseUrl}/theme-page`,
  BLOG_PAGE: `${baseUrl}/blog-page`,
  SITE_INFO: `${baseUrl}/site-info`

}
export const OMD_POST_ENDPOINT = {
  CONTACT_INQUIRY: `${baseUrl}/contact-form-inquiries`,
  CAREER_INQUIRY: `${baseUrl}/career-inquiries`,
}
