import { SiteInfoActionTypes } from '../store/slices/siteInfoSlice';

/**
 * To get site info data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getSiteInfoSection = (payload) => ({
  type: SiteInfoActionTypes.FETCH_SITE_INFO,
  payload,
});
