import { call, put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import aboutUsService from '../services/AboutUsService';
import {
  AboutUsActionTypes,
  aboutUsActions,
} from '../store/slices/aboutUsSlice';
import { loaderActions } from '../store/slices/loaderSlice';

// saga handler to fetch About Us sectionwise and update redux store as per the api payload
export const fetchAboutUsSection = function* (action) {
  try {
    yield put(loaderActions.showLoader());
    const response = yield call(
      aboutUsService.getAboutUsSection,
      action.payload
    );
    const responseData = yield get(response, 'data');
    const res = yield put(
      aboutUsActions.setAboutUsSection({
        sectionName: 'SectionData',
        data: responseData,
      })
    );

  } catch (err) {
    const errorMessage = err?.response?.data?.message || err.message;
    console.error(errorMessage);
  } finally {
    yield put(loaderActions.hideLoader());
  }
};

export default function* aboutUsSaga() {
  yield takeLatest(AboutUsActionTypes.FETCH_ABOUT_US, fetchAboutUsSection);
}
