import { OMD_API_ENDPOINTS } from '../config/apiConfig';
import HttpClient from '../helpers/HttpClient';


class BlogPageService {
  // POST request to fetch About Us page section wise
  async getBlogPageData(payload) {
    const response = await HttpClient.get(OMD_API_ENDPOINTS.BLOG_PAGE);

    return response;
  }
}

const blogPageDataService = new BlogPageService();
export default blogPageDataService;
