import { ThemePageActionTypes } from "../store/slices/themePageSlice";

/**
 * To get about us page data section wise
 * @param {object} payload consisting of: Page & Section
 */
export const getThemePageSection = (payload) => ({
  type: ThemePageActionTypes.FETCH_THEME_PAGE,
  payload,
});
