import React from 'react';
import TermsHeader from '../../../assets/TermsContionsHeader.jpg';
import TermsHeaderMobile from '../../../assets/Terms-os-usesHeaderMobile.jpg';
import parse from 'html-react-parser';

const Header = ({ section1 }) => {
  let title = '';
  let image = '';
  let image2 = '';
  let imageAltText = '';
  let image2AltText = '';

  if (section1?.Heading) {
    title = parse(section1?.Heading);
    image = section1?.BannerImage?.url;
    imageAltText = section1?.BannerImage?.altTag;
    image2 = section1?.BannerImageMobile?.url;
    image2AltText = section1?.BannerImageMobile?.altTag;
  }

  return (
    <div className='container-fluid p-0 StickScroll'>
      <div className='inSideHeader'>
        <img src={image} alt={imageAltText} className='WebImg' />
        {/* <img src={TermsHeader} alt='' className='WebImg' /> */}
        <img src={image2} alt={image2AltText} className='MobileImg' />
        {/* <img src={TermsHeaderMobile} alt='' className='MobileImg' /> */}
        <div className='inSideHeaderCaption' data-aos='fade-right'>
          <h1> {title}</h1>
          {/* <h1> Terms of Use</h1> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
